/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Website editor styles */

.wb-preview-div {
  min-height: 75vh;
  overflow-y: scroll;
  margin: 60px 20px;
  padding-bottom: 20px;
}

.wb-preview-div .wb-editor-block {
  border: solid 1px transparent;
}

.wb-preview-div .wb-editor-block:hover {
  border: solid 1px #c4c4c4;
}

.wb-container-max-1100 {
  max-width: 1100px !important;
}

.wb-custom-search-input {
  height: 30px;
}

.wb-left-menu-wrapper {
  padding-bottom: 100px;
}

.wb-header-download {
  height: 50px;
  padding: 19px 27px;
  background: #fa2356;
  font-size: 14px;
  color: #fff;
  background-color: #fa2356;
  border-width: 2px;
  cursor: pointer;
}
.wb-header-download:hover {
  height: 50px;
  padding: 19px 27px;
  font-size: 14px;
  color: #fff;
  background-color: #d21d48;
  border-color: #d21d48;
  border-width: 2px;
}

.force-disabled {
  cursor: not-allowed !important;
  background: #dddddd !important;
  pointer-events: none !important;
}

.wb-header-preview {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 18px;
  color: #000;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  line-height: 1.2em;
  cursor: pointer;
  text-decoration: none;
}
.exit-builder-style {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 18px;
  color: #000;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  line-height: 1.2em;
  cursor: pointer;
  text-decoration: none;
  color: #fa2356;
  background-color: initial;
  background-color: #fa23560d;
}
.wb-header-preview:hover {
  color: #022859;
  background-color: initial;
  /* background-color: #fa23560d; */
  background-color: #02285912;
}
.wb-header-preview:active {
  color: #022859;
  border-bottom: solid 2px #022859;
}
.download-btn:hover {
  color: #022859;
  background-color: initial;
  background-color: #02285912;
}

.wb-save .spinner-border {
  height: 20px;
  width: 20px;
  border-width: 2px;
  vertical-align: middle;
  color: #022859;
}

.wb-header-theme {
  border-width: 0;
  border-radius: 50%;
}

.wb-menu-icon:hover {
  background: #02285912;
}
.wb-menu-icon-page-section {
  height: 42px;
  margin: 0px 5px 5px 8px;
  border-radius: 4px;
  border: 1px solid #dfe5eb;
  color: #3b4057;
  display: flex;
  align-items: center;

  justify-content: space-between;
  background: transparent;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.font-style {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px !important;
}
.wb-menu-icon-page-section:hover {
  background-color: #d6e6fe;
  border-color: #3485fe;
}

.section-option-icon {
  padding: 3px;
  cursor: pointer;
  border: 1px solid #3485fe;
  border-radius: 2rem;
  visibility: hidden;
}
.section-option-icon:hover {
  background-color: #3485fec8;
  color: #fff;
}

.wb-menu-icon-page-section:hover .section-option-icon {
  visibility: visible;
}

.wb-image-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;
}

.wb-image {
  margin: 0.5rem 0.25rem 0rem;
  position: relative;
}
.wb-image:hover img {
  opacity: 0.8;
}
.image-icon {
  width: 20px;
  position: absolute;
  right: 2%;
  font-size: 0.6rem;
  height: 20px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.image-icon:hover {
  background: #f1f1f1;
}
.image-icon i {
  color: #666666;
}

.wb-image .image-icon {
  visibility: hidden;
}

.wb-image:hover .image-icon {
  visibility: visible;
}
.add-section-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.section-image .add-section-button {
  visibility: hidden;
}
.section-image:hover .add-section-button {
  visibility: visible;
}
.section-image .image-icon {
  visibility: hidden;
}

.section-image:hover .image-icon {
  visibility: visible;
}

.funnel-card {
  background-color: #fff;
  height: 150px !important;
  width: 240px !important;
  border-radius: 8px;
  box-shadow:  rgba(64,60,67,0.16) 0 1px 5px 1px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.funnel-circle{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #023373;
  background-color: #e8eaec;
  /* border: 1px solid #023373; */
  font-size: 16px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
  white-space: nowrap;
}

.transition-class {
  transition: 0.3s ease-in-out;
}

.wb-menu-icon-selected {
  background: #02285912;
}
.wb-add-section-modal-selected {
  background: #02285912;
}
.wb-add-section-modal:hover {
  background: #02285912;
}

.wb-container-max-1100 {
  max-width: 1100px !important;
}

.wb-menu-icon-row {
  width: 40px;
  float: left;
  margin-right: 10px;
  padding-bottom: 150px;
}

.wb-custom-search-input {
  height: 30px;
}

.wb-left-menu-wrapper {
  padding-bottom: 100px;
}

.wb-header-download {
  height: 50px;
  padding: 19px 27px;
  background: #fa2356;
  font-size: 14px;
  color: #fff;
  background-color: #fa2356;
  border-width: 2px;
  cursor: pointer;
}
.wb-header-download:hover {
  height: 50px;
  padding: 19px 27px;
  font-size: 14px;
  color: #fff;
  background-color: #d21d48;
  border-color: #d21d48;
  border-width: 2px;
}

/* Theme selection tab styling */

.wb-style-selection-tab-left-drawer {
  height: 40px;
  color: #666666;
  overflow: hidden;
  font-size: 0.8rem;
  text-decoration: none;
  width: 235px;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  right: 0%;
  padding: 0.7rem 0.8rem;
  border-bottom: solid 2px #bdbdbd;
}
.wb-style-selection-tab {
  color: #022859;
  vertical-align: middle;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  cursor: pointer;
  text-decoration: none;
  padding: 2px 0px;
  border-bottom: solid 2px transparent;
}

.wb-style-selection-tab:hover {
  color: #022859;
  background-color: initial;
  background-color: #02285912;
}

.wb-style-selection-tab:active {
  color: #022859;
  border-bottom: solid 2px #022859;
}

.wb-style-selection-tab-active {
  color: #022859;
  border-bottom: solid 2px #022859;
  background-color: #02285912;
}

.wb-icons-box {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dee2e6;
}

.wb-icons-box-active {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dee2e6;
  background-color: #02285912;
}

.wb-icons-box:hover {
  background-color: #02285912;
}

.custom-icon-select {
  border: none;
  padding: 2px 8px;
  background-image: url("https://api.iconify.design/gridicons/dropdown.svg");
  background-position: right; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-label {
  color: black;
  font-size: 12px;
}
.form-control-sm-custom {
  height: calc(1.5em + 0.3rem + 2px);
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.custom-icon-font-family {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/bx/bx-font-family.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-icon-font-size {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/bx/bx-font-size.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-icon-line-height {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/ri/line-height.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.extra-small-btn {
  font-size: x-small;
  padding: 3px 8px;
}
/* Custom classes */
.icon-container {
  border-radius: 1px;
  border: solid 1px transparent;
}
.icon-container:hover {
  background-color: #fa23560d;
  cursor: pointer;
}
.fit-content {
  width: fit-content;
}

/* custom grid system for only editor */

.wb-editor-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}

.wb-editor-col-2 {
  flex: 0 0 auto;
  width: 16.66%;
}

.wb-editor-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.wb-editor-col-4 {
  flex: 0 0 auto;
  width: 33%;
}

.wb-editor-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

/* Media files Drop Zone CSS */
.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}
.image-name-size {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  color: gray;
  font-size: 12px;
}

/* Gallery */

.search-box-gallery {
  position: absolute;
  right: 8px;
  top: 6px;
  background: #f8f8f8;
  height: 30px;
  border-radius: 30px;
  display: flex;
  transition: 0.3s ease-in-out;
}
/* .search-box:hover > input{
  width: 160px;
  padding: 0.4rem 0.8rem;
} */
.input {
  display: none;
}
.search-box-gallery-width {
  width: 150px;
  display: block;
  padding: 0rem 0.8rem;
  border: none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  transition: 0.3s ease-in-out;
  line-height: 30px;
  color: #666666;
}

/* Image Editor */

.image-editor-button {
  padding: 2px 8px 2px;
  cursor: pointer;
  color: gray;
}
.image-editor-button-active {
  border-bottom: 2px solid;
  border-color: #022859;
  color: #022859 !important;
  background-color: #02285912;
}


/* Row Setting */

.row-layout-option{
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Section Setting */

.section-name:disabled{
background-color: #fff !important;
}


/* Right Menu Wrappeer */

.right-menu-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 2000;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
}
.edit-button-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.iframe-edit-button-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 1200;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-navlist-button {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}

.wb-list-setting-button{
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-layout-setting-setting-button{
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-box-setting-setting-button{
  background-color: #fff;
  position: absolute;
  left: 50%;
  z-index: 10;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
/* =========================== Choose Template Modal ================== */

.create-project-card {
  box-shadow: 0px 5px 5px rgb(0 0 0 / 7%);
  padding: 8px;
}
.create-project-card:hover {
  /* transform: translateY(-2%); */
  transition: ease-in-out 0.3s;
}
.create-project-card .create-project-edit-button {
  position: relative;
  height: 100%;
  top: -100%;
  background-color: rgba(22, 45, 61, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-project-card .create-project-edit-button {
  opacity: 0;
}
.create-project-card:hover .create-project-edit-button {
  opacity: 1;
}

/* ===========================From Setting 3rd party tab ================== */
.third-party-box {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.third-party-box-active {
  border: 1px solid #d21d48;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin: 16px 0px;
  height: 55px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
/* ===========================Animation================== */

.animation-box-LTR {
  animation: move-left 0.3s ease-in-out forwards;
}

@keyframes move-left {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.tox-toolbar__overflow {
  animation: fade-in 0.15s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}


/* // Ai Assistant */

.example-box{
  background-color: #f7f7f8;
  padding: 12px;
  width: 70%;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
}

.microphone {
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.microphone.active {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
      background-color: #4286f42f;
  }
  to {
      background-color: #4c9baf0e;
  }
}

:root {
  --wb-main-primary-color: #022859;
  --wb-main-secondary-color: #3d2ca5;
  --wb-main-primary-color-hover: #02316e;
  --wb-main-primary-text: #fff;
  --wb-main-primary-text-hover: #fff;
  --wb-main-button-text-font-size: 14;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  /* background-color: #f0f4f7; */
  font-family: "Roboto", "Montserrat", sans-serif !important;
}

/* .MuiToolbar-root {
  padding-right: 0 !important;
} */

legend {
  float: unset;
}

.pin-field swd-pin-field {
  justify-content: space-between;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem hsla(216, 98%, 52%, 0.25);
}

.sidebar-title {
  font-size: 12px;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}
.design-wrapper {
  margin-top: 20px;
  padding: 0px 15px 0px 15px;
}

.rounded-box {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.rounded-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

/* ------------------------------------------------------------------ */
.Loader {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loader {
  width: 120px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

div.loader span {
  width: 1px;
  height: 100%;
  background-color: #27c5fe;
  animation: loading 1.5s infinite ease-in-out;
}

div.loader span:nth-child(1) {
  animation-delay: 0s;
}

div.loader span:nth-child(2) {
  animation-delay: 0.5s;
}

div.loader span:nth-child(3) {
  animation-delay: 0.3s;
}

div.loader span:nth-child(4) {
  animation-delay: 0.1s;
}
div.loader span:nth-child(5) {
  animation-delay: 0.9s;
}

div.loader span:nth-child(6) {
  animation-delay: 0s;
}

div.loader span:nth-child(7) {
  animation-delay: 0.7s;
}

div.loader span:nth-child(8) {
  animation-delay: 0.1s;
}
div.loader span:nth-child(9) {
  animation-delay: 0.6s;
}

div.loader span:nth-child(10) {
  animation-delay: 0.8s;
}

div.loader span:nth-child(11) {
  animation-delay: 0.4s;
}

div.loader span:nth-child(12) {
  animation-delay: 0s;
}
div.loader span:nth-child(13) {
  animation-delay: 0.9s;
}

div.loader span:nth-child(14) {
  animation-delay: 0.35s;
}

div.loader span:nth-child(15) {
  animation-delay: 0.1s;
}

div.loader span:nth-child(16) {
  animation-delay: 0.5s;
}
div.loader span:nth-child(17) {
  animation-delay: 0.7s;
}

div.loader span:nth-child(18) {
  animation-delay: 0.3s;
}
div.loader span:nth-child(19) {
  animation-delay: 0.9s;
}
div.loader span:nth-child(20) {
  animation-delay: 0.8s;
}
div.loader span:nth-child(21) {
  animation-delay: 0.2s;
}
div.loader span:nth-child(22) {
  animation-delay: 0.7s;
}
div.loader span:nth-child(23) {
  animation-delay: 0.5s;
}
div.loader span:nth-child(24) {
  animation-delay: 0.2s;
}
div.loader span:nth-child(25) {
  animation-delay: 0.2s;
}

@keyframes loading {
  0%,
  100% {
    transform: scaleY(100%);
  }

  50% {
    transform: scaleY(60%);
    background-color: #af3fcf;
  }
  25% {
    transform: scaleY(35%);
    background-color: #27c5fe;
  }
}

/* --------------------------------------------------------------------------------------------------- */
/* 
.loader,
.loader:before,
.loader:after {
  background: #458787;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #458787;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
} */

/* -------------------------------------------------------------------------------------------------- */

/* .rounded-card:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f0f4f7;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  min-width: 200px;
  height: 300px;
  cursor: pointer;
} */

iframe::-webkit-scrollbar {
  display: none;
}
/* =================================Right Drawer Button Styles============================= */

.btn-outlined-drawer {
  background: transparent !important;
  color: var(--wb-website-text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--wb-website-theme-secondary-color) !important;
  border-width: 0.125rem !important;
  display: inline-block !important;
  padding: 0.475rem 1.635rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}

.btn-outlined-drawer:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
  border: 0.125rem solid var(--wb-website-hover-background-color) !important;
}
.btn-text-drawer {
  background: transparent !important;
  color: var(--wb-website-theme-secondary-color) !important;
  display: inline-block !important;
  padding: 0.6rem 1.76rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.btn-text-drawer:hover {
  color: var(--wb-website-text-primary-color) !important;
}
.btn-contained-drawer {
  background: var(--wb-website-theme-secondary-color) !important;
  color: var(--wb-website-text-secondary-color) !important;
  display: inline-block !important;
  padding: 0.6rem 1.76rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.btn-contained-drawer:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
}

/* =================================================================================== */

.dark-grey-color {
  color: #747474;
}

.card-heading {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px 0px;
  border-radius: 6px 6px 0px 0px;
}
.card-image-btn {
  width: 100%;
  height: 200px;
}

.card-image-btn-loader {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image-btn-model {
  width: 100%;
  height: 50px;
}

/*  */

#ReactSimpleImageViewer {
  margin-top: 50px;
  z-index: 999;
}

.react-simple-image-viewer__slide {
  margin-top: -50px;
}

.custom-link {
  color: black;
  cursor: pointer;
  font-size: 12px;
}

.custom-link:hover {
  color: #f04756;
  cursor: pointer;
}

.selection-box {
  border: dashed 1px grey;
  width: 190px;
  height: 100px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: pointer;
  background-color: transparent;
  transition: transform 0.4s ease-in-out;
  margin-top: 10px;
}

.selection-box:hover {
  background-color: #e7e7e7;
}

.selection-box-heading {
  transition: transform 0.3s ease-in-out;
}

.selection-box:hover > .selection-box-heading {
  transform: scale(1.1);
}

.left-menu-toggle {
  position: fixed !important;
  transition: transform 4s ease-in-out;
  top: 65px;
  left: -18px;
  z-index: 1200;
}

.left-menu-toggle:hover {
  left: -15px;
}

.edit-able-section {
  border: solid 1px transparent;
  position: relative;
}

.edit-able-section:hover {
  border: solid 1px dodgerblue;
}

.edit-able-section-actions {
  font-weight: bold;
  display: none;
  position: absolute;
  padding: 0px 7px;
  right: 5px;
  top: 5px;
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  border-color: #fff;
  border-width: 2px;
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
  transition-property: border-color, box-shadow, color;
}
.edit-able-section-actions:hover {
  font-weight: bold;
}

.edit-able-section:hover > .edit-able-section-actions {
  display: inline;
  z-index: 99;
}
.edit-able-section-actions-add-section {
  font-weight: bold;
  display: none;
  cursor: pointer;
  position: absolute;
  padding: 0px 7px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  border-color: #fff;
  border-width: 2px;
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
  transition-property: border-color, box-shadow, color;
}
.edit-able-section-actions-add-section:hover {
  font-weight: bold;
  color: darkgreen;
}

.edit-able-section:hover > .edit-able-section-actions-add-section {
  display: inline;
  z-index: 99;
}

.edit-able-section-actions-delete {
  cursor: pointer;
}

.edit-able-section-actions-delete:hover {
  cursor: pointer;
  color: #fa2356;
}

/* grid system divs */
.break-able-section div.row:hover {
  outline: blue solid 1px;
}
.break-able-section:hover div.row:hover > .wp-row-block {
  display: inherit;
  justify-content: flex-start;
}

.wp-row-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}

.wp-row-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-row-click:hover {
  cursor: pointer;
  color: red !important;
}
/* Custom Buttons */
.custom-button {
  font-size: var(--wb-main-button-text-font-size);
  padding: 10px 24px;
  border-radius: 50px;
  border: solid 2px red;
  background-color: #fff;
  border-color: #fa2356;
  color: #fa2356;
  padding: 10px 24px;
}

.custom-button:hover {
  background-color: #d21d48;
  border-color: #d21d48;
  color: white;
}
.custom-button:disabled {
  background-color: grey;
  border-color: grey;
  color: white;
}

.main-primary-btn {
  background-color: var(--wb-main-primary-color);
  color: var(--wb-main-primary-text);
}

.main-btn-text {
  font-size: var(--wb-main-button-text-font-size);
  padding: 10px 24px;
}

.main-primary-btn:hover {
  background-color: var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-text-hover);
}
.form-checkbox-color {
  background-color: #e4e4e4;
  width: max-content;
}
.formsetting-label-parent-div {
  user-select: none;
  background-color: #e4e4e4;
}
.formsetting-div-color-silver {
  user-select: none;
  background-color: #d0d0d0;
}

.form-label-checkbox-color- {
  background-color: #e4e4e4;
}

/* Fade in, Fade out */

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

/*  */

.type-card-small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  font-size: larger;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  min-width: 150px;
  height: 100px;
}

.type-card-small:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--wb-main-secondary-color);
  color: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  height: 100px;
  cursor: pointer;
}

.type-card-small-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--wb-main-secondary-color);
  color: #ffffff;
  font-size: larger;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  min-width: 150px;
  height: 100px;
}

.border-left-blue {
  border-left: solid 2px var(--wb-main-secondary-color);
}
.border-right-blue {
  border-right: solid 2px var(--wb-main-secondary-color);
}

/* Login screen */

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: 110%;
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.wb-dropdown-link {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-align: left;
  cursor: pointer;
  background-color: #fff;
  border: none;
}

.main-dashboard-website-name {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-family: "Roboto", fallback-font;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  letter-spacing: 0.0313em;
}
.main-dashboard-website-status {
  color: #9da3a9;
}
.main-dashboard-website-placeholder {
  opacity: 1;
  cursor: pointer;
}
.main-dashboard-website-placeholder:hover {
  opacity: 0.5;
}

.column-block-list-edit-button {
  visibility: hidden;
}
.column-block-list-edit-button:hover {
  color: #fa2356;
  cursor: pointer;
}

.column-block-list:hover .column-block-list-edit-button {
  visibility: visible;
  margin-top: 3px;
}
.style-toggle-button {
  cursor: pointer;
  visibility: visible;
  /* margin-top: 1px !important; */
}
.style-toggle-button:hover {
  color: #fa2356;
}
.main-dashboard-website-action {
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 78%;
  width: 100%;
  border-radius: 0%;
}
.edit-thumbnail-action {
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 65%;
  width: 100%;
  border-radius: 0%;
}
.page_menu_option {
  position: absolute;
  right: 10px;
  margin-top: 4px;
  padding: 3px;
  z-index: 99;
  background-color: lightslategray;
  border-radius: 50%;
  transition: all 0.1s;
}
.page_menu_option_for_version {
  position: absolute;
  margin-top: 4px;
  right: 10px;

  padding: 3px;
  z-index: 99;
  background-color: lightslategray;
  border-radius: 50%;
  transition: all 0.1s;
}
.version_parent_div {
  position: relative;
}
.version_card {
  border: 1px solid black;
}

/* .page_menu_option:hover {
  padding: 3px;
} */

.main-dashboard-website-action-btn {
  font-size: 10px;
  padding: 10px 24px;
  border-width: 0;
  font-weight: 500;
  line-height: 1.67;
  width: 230px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.188em;
  cursor: pointer;
  border: 2px solid var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-color-hover);
  border-radius: 50px;
  background-color: white;
}
.main-dashboard-website-more-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 11;
  cursor: pointer;
  border: 2px solid var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-color-hover);
  border-radius: 50px;
}

.main-dashboard-website-more-icon:hover {
  color: white;
  background-color: var(--wb-main-primary-color-hover);
}
.main-dashboard-website-action-btn:hover {
  color: white;
  background-color: var(--wb-main-primary-color-hover);
}

.visible-parent-hover {
  visibility: hidden;
}

.main-dashboard-website-action:hover .visible-parent-hover {
  visibility: visible;
}
.edit-thumbnail-action:hover .visible-parent-hover {
  visibility: visible;
}
.icon-hover-class:hover {
  cursor: pointer !important;
  background-color: #adadad;
}
.helping-category-card {
  display: flex;
  align-content: center;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  cursor: pointer;
}
.helping-category-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.support-ticket-wrapper {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.support-profile {
  display: flex;
}

.upload-button {
  background: rgb(54 117 136 / 8%) !important;
}

.bottom-buttons {
  position: absolute;
  bottom: 50px;
  background-color: white;
  padding-top: 10px;
}

/* wb-box-block */
.break-able-section div.wb-box:hover {
  outline: blue solid 1px;
  position: relative;
}
.break-able-section:hover div.wb-box:hover > .wp-box-block {
  font-family: none;
  display: block;
  position: absolute;
  top: -1px;
  right: 50%;
}

.wp-box-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-box-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-left-radius: 5px;
  color: white !important;
}
.wp-box-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-box-block end */

/* wb-list-block */
/* .break-able-section ul:hover {
  outline: blue solid 1px;
  position: relative;
} */
/* .break-able-section ol:hover {
  outline: blue solid 1px;
  position: relative;
} */
.break-able-section:hover ul:hover > .wp-list-block {
  display: inherit;
}
.break-able-section:hover ol:hover > .wp-list-block {
  display: inherit;
}

.wp-list-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-list-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-list-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */

/* wp-image-block */

.break-able-section img:hover {
  outline: rgb(53, 119, 27) solid 1px;
}

/* wb-image-block end */

/* wb-form-block */
.break-able-section form:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover form:hover > .wp-form-block {
  display: inherit;
}

.wp-form-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: right !important;
  margin-inline: 51px;
}
.wp-form-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-left-radius: 5px;
  color: white !important;
}
.wp-form-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */



/* wb-form-block */
.break-able-section div.wb-iframe:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover div.wb-iframe:hover > .wp-iframe-block {
  display: inherit;
}

.wp-iframe-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: center !important;
}
.wp-iframe-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-iframe-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */


/* wb-form-block */
.break-able-section div.wb-plan-card:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover div.wb-plan-card:hover > .wp-plan-card-block {
  display: inherit;
  position: absolute;
  top: 0%;
  width: 100%;
}

.wp-plan-card-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-plan-card-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-plan-card-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Concert+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palette+Mosaic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lustria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif+Caption:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charis+SIL:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Palanquin+Dark:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

/* CLASSES */
.font_muktra {
  font-family: "Mukta", sans-serif;
}
.font_sora {
  font-family: "Sora", sans-serif;
}
.font_Hahmlet {
  font-family: "Hahmlet", serif;
}
.font_andada-pro {
  font-family: "Andada Pro", serif;
}
.font_work-sans {
  font-family: "Work Sans", sans-serif;
}
.font_raleway {
  font-family: "Raleway", sans-serif;
}
.font_spectral {
  font-family: "Spectral", serif;
}
.font_cormorant {
  font-family: "Cormorant", serif;
}
.font_roboto {
  font-family: "Roboto", sans-serif;
}
.font_archivo {
  font-family: "Archivo", sans-serif;
}

.font_playfair {
  font-family: "Playfair Display", serif;
}

.font_biorhyme {
  font-family: "BioRhyme", serif;
}

.font_lora {
  font-family: "Lora", serif;
}

.font_manrope {
  font-family: "Manrope", sans-serif;
}

.font_encode-sans {
  font-family: "Encode Sans", sans-serif;
}

.font_inter {
  font-family: "Inter", sans-serif;
}

.font_epilogue {
  font-family: "Epilogue", sans-serif;
}

.font_open-sans {
  font-family: "Open Sans", sans-serif;
}

.font_oxygen {
  font-family: "Oxygen", sans-serif;
}

.font_source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}

.font_nunito {
  font-family: "Nunito", sans-serif;
}

.font_poppins {
  font-family: "Poppins", sans-serif;
}

.font_montserrat {
  font-family: "Montserrat", sans-serif;
}

.font_oswald {
  font-family: "Oswald", sans-serif;
}

.font_old-standard-tt {
  font-family: "Old Standard TT", serif;
}

.font_anton {
  font-family: "Anton", sans-serif;
}

.font_lato {
  font-family: "Lato", sans-serif;
}

.font_philosopher {
  font-family: "Philosopher", sans-serif;
}

.font_sanchez {
  font-family: "Sanchez", serif;
}

.font_rubik {
  font-family: "Rubik", sans-serif;
}

.font_rokkitt {
  font-family: "Rokkitt", serif;
}

.font_rambla {
  font-family: "Rambla", sans-serif;
}

.font_noto-sans {
  font-family: "Noto Sans", sans-serif;
}

.font_mulish {
  font-family: "Mulish", sans-serif;
}

.font_merriweather {
  font-family: "Merriweather Sans", sans-serif;
}

.font_karla {
  font-family: "Karla", sans-serif;
}

.font_josefin-sans {
  font-family: "Josefin Sans", sans-serif;
}

.font_fira-sans {
  font-family: "Fira Sans", sans-serif;
}

.font_fjalla-one {
  font-family: "Fjalla One", sans-serif;
}

.font_dm-sans {
  font-family: "DM Sans", sans-serif;
}

.font_concert-one {
  font-family: "Concert One", cursive;
}

.font_cardo {
  font-family: "Cardo", serif;
}

.font_cabin {
  font-family: "Cabin", sans-serif;
}

.font_bree-sarif {
  font-family: "Bree Serif", serif;
}

.font_arimo {
  font-family: "Arimo", sans-serif;
}

.font_abril-fatface {
  font-family: "Abril Fatface", cursive;
}

.font_readex-pro {
  font-family: "Readex Pro", sans-serif;
}

.font_ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.font_courgette {
  font-family: "Courgette", cursive;
}

.font_saira {
  font-family: "Saira", sans-serif;
}

.font_lobster_two {
  font-family: "Lobster Two", cursive;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-heebo {
  font-family: "Heebo", sans-serif;
}

.font_teko {
  font-family: "Teko", sans-serif;
}
.font_bebas_Neue {
  font-family: "Bebas Neue";
}
.font_manrope {
  font-family: "Manrope";
}
.font_nova_square {
  font-family: "Nova Square";
}
.font_palette_mosaic {
  font-family: "Palette Mosaic";
}
.font_hurricane {
  font-family: "Hurricane";
}
.font_red_hat_mono {
  font-family: "Red Hat Mono";
}
.font_dancing_script {
  font-family: "Dancing Script";
}
.font_kaushan_script {
  font-family: "Kaushan Script";
}
.font_libre_bodoni {
  font-family: "Libre Bodoni";
}
.font_square_peg {
  font-family: "Square Peg";
}
.font_libre_bodoni {
  font-family: "Libre Bodoni";
}
.font_square_peg {
  font-family: "Square Peg";
}
.font_black_han_sans {
  font-family: "Black Han Sans", sans-serif;
}
.font_lustria {
  font-family: "Lustria", serif;
}
.font_pt_serif {
  font-family: "PT Serif", serif;
}
.font_pt_serif_caption {
  font-family: "PT Serif Caption", serif;
}
.font_poiret_one {
  font-family: "Poiret One", cursive;
}
.font_libre_baskerville {
  font-family: "Libre Baskerville", serif;
}
.font_ibm_plex_sans {
  font-family: "IBM Plex Sans", sans-serif;
}
.ibm_plex_serif {
  font-family: "IBM Plex Serif", serif;
}
.font_jost {
  font-family: "Jost", sans-serif;
}
.font_charis_sil {
  font-family: "Charis SIL";
}
.font_barlow {
  font-family: "Barlow", sans-serif;
}
.font_prata {
  font-family: 'Prata', serif;
}
.font_mrs_saint_delafield{
  font-family: 'Mrs Saint Delafield', cursive;
}
.font_sarabun{
  font-family: 'Sarabun', sans-serif;
}
.font_Amatic_SC{
  font-family: 'Amatic SC', sans-serif;
}
.font_palanquin_dark{
  font-family: 'Palanquin Dark', sans-serif;
}
.font_figtree{
  font-family: 'Figtree', sans-serif;
}
.font_eb_garamond{
  font-family: 'EB Garamond', sans-serif;
}
.font_quattrocento{
  font-family: 'Quattrocento', sans-serif;
}
.font_exo_2{
  font-family: 'Exo 2', sans-serif;
}
.font_Titillium_Web{
  font-family: 'Titillium Web', sans-serif;
}
.font_tenor_sans{
  font-family: 'Tenor Sans', sans-serif;
}
@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rokkitt:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nova+Square&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Palette+Mosaic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hurricane&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lustria&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif+Caption:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Charis+SIL:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Palanquin+Dark:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap);
/* CLASSES */
.font_muktra {
  font-family: "Mukta", sans-serif;
}
.font_sora {
  font-family: "Sora", sans-serif;
}
.font_Hahmlet {
  font-family: "Hahmlet", serif;
}
.font_andada-pro {
  font-family: "Andada Pro", serif;
}
.font_work-sans {
  font-family: "Work Sans", sans-serif;
}
.font_raleway {
  font-family: "Raleway", sans-serif;
}
.font_spectral {
  font-family: "Spectral", serif;
}
.font_cormorant {
  font-family: "Cormorant", serif;
}
.font_roboto {
  font-family: "Roboto", sans-serif;
}
.font_archivo {
  font-family: "Archivo", sans-serif;
}

.font_playfair {
  font-family: "Playfair Display", serif;
}

.font_biorhyme {
  font-family: "BioRhyme", serif;
}

.font_lora {
  font-family: "Lora", serif;
}

.font_manrope {
  font-family: "Manrope", sans-serif;
}

.font_encode-sans {
  font-family: "Encode Sans", sans-serif;
}

.font_inter {
  font-family: "Inter", sans-serif;
}

.font_epilogue {
  font-family: "Epilogue", sans-serif;
}

.font_open-sans {
  font-family: "Open Sans", sans-serif;
}

.font_oxygen {
  font-family: "Oxygen", sans-serif;
}

.font_source-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}

.font_nunito {
  font-family: "Nunito", sans-serif;
}

.font_poppins {
  font-family: "Poppins", sans-serif;
}

.font_montserrat {
  font-family: "Montserrat", sans-serif;
}

.font_oswald {
  font-family: "Oswald", sans-serif;
}

.font_old-standard-tt {
  font-family: "Old Standard TT", serif;
}

.font_anton {
  font-family: "Anton", sans-serif;
}

.font_lato {
  font-family: "Lato", sans-serif;
}

.font_philosopher {
  font-family: "Philosopher", sans-serif;
}

.font_sanchez {
  font-family: "Sanchez", serif;
}

.font_rubik {
  font-family: "Rubik", sans-serif;
}

.font_rokkitt {
  font-family: "Rokkitt", serif;
}

.font_rambla {
  font-family: "Rambla", sans-serif;
}

.font_noto-sans {
  font-family: "Noto Sans", sans-serif;
}

.font_mulish {
  font-family: "Mulish", sans-serif;
}

.font_merriweather {
  font-family: "Merriweather Sans", sans-serif;
}

.font_karla {
  font-family: "Karla", sans-serif;
}

.font_josefin-sans {
  font-family: "Josefin Sans", sans-serif;
}

.font_fira-sans {
  font-family: "Fira Sans", sans-serif;
}

.font_fjalla-one {
  font-family: "Fjalla One", sans-serif;
}

.font_dm-sans {
  font-family: "DM Sans", sans-serif;
}

.font_concert-one {
  font-family: "Concert One", cursive;
}

.font_cardo {
  font-family: "Cardo", serif;
}

.font_cabin {
  font-family: "Cabin", sans-serif;
}

.font_bree-sarif {
  font-family: "Bree Serif", serif;
}

.font_arimo {
  font-family: "Arimo", sans-serif;
}

.font_abril-fatface {
  font-family: "Abril Fatface", cursive;
}

.font_readex-pro {
  font-family: "Readex Pro", sans-serif;
}

.font_ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.font_courgette {
  font-family: "Courgette", cursive;
}

.font_saira {
  font-family: "Saira", sans-serif;
}

.font_lobster_two {
  font-family: "Lobster Two", cursive;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-heebo {
  font-family: "Heebo", sans-serif;
}

.font_teko {
  font-family: "Teko", sans-serif;
}
.font_bebas_Neue {
  font-family: "Bebas Neue";
}
.font_manrope {
  font-family: "Manrope";
}
.font_nova_square {
  font-family: "Nova Square";
}
.font_palette_mosaic {
  font-family: "Palette Mosaic";
}
.font_hurricane {
  font-family: "Hurricane";
}
.font_red_hat_mono {
  font-family: "Red Hat Mono";
}
.font_dancing_script {
  font-family: "Dancing Script";
}
.font_kaushan_script {
  font-family: "Kaushan Script";
}
.font_libre_bodoni {
  font-family: "Libre Bodoni";
}
.font_square_peg {
  font-family: "Square Peg";
}
.font_libre_bodoni {
  font-family: "Libre Bodoni";
}
.font_square_peg {
  font-family: "Square Peg";
}
.font_black_han_sans {
  font-family: "Black Han Sans", sans-serif;
}
.font_lustria {
  font-family: "Lustria", serif;
}
.font_pt_serif {
  font-family: "PT Serif", serif;
}
.font_pt_serif_caption {
  font-family: "PT Serif Caption", serif;
}
.font_poiret_one {
  font-family: "Poiret One", cursive;
}
.font_libre_baskerville {
  font-family: "Libre Baskerville", serif;
}
.font_ibm_plex_sans {
  font-family: "IBM Plex Sans", sans-serif;
}
.ibm_plex_serif {
  font-family: "IBM Plex Serif", serif;
}
.font_jost {
  font-family: "Jost", sans-serif;
}
.font_charis_sil {
  font-family: "Charis SIL";
}
.font_barlow {
  font-family: "Barlow", sans-serif;
}
.font_prata {
  font-family: 'Prata', serif;
}
.font_mrs_saint_delafield{
  font-family: 'Mrs Saint Delafield', cursive;
}
.font_sarabun{
  font-family: 'Sarabun', sans-serif;
}
.font_Amatic_SC{
  font-family: 'Amatic SC', sans-serif;
}
.font_palanquin_dark{
  font-family: 'Palanquin Dark', sans-serif;
}
.font_figtree{
  font-family: 'Figtree', sans-serif;
}
.font_eb_garamond{
  font-family: 'EB Garamond', sans-serif;
}
.font_quattrocento{
  font-family: 'Quattrocento', sans-serif;
}
.font_exo_2{
  font-family: 'Exo 2', sans-serif;
}
.font_Titillium_Web{
  font-family: 'Titillium Web', sans-serif;
}
.font_tenor_sans{
  font-family: 'Tenor Sans', sans-serif;
}
:root {
  --wb-main-primary-color: #022859;
  --wb-main-secondary-color: #3d2ca5;
  --wb-main-primary-color-hover: #02316e;
  --wb-main-primary-text: #fff;
  --wb-main-primary-text-hover: #fff;
  --wb-main-button-text-font-size: 14;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  /* background-color: #f0f4f7; */
  font-family: "Roboto", "Montserrat", sans-serif !important;
}

/* .MuiToolbar-root {
  padding-right: 0 !important;
} */

legend {
  float: unset;
}

.pin-field swd-pin-field {
  justify-content: space-between;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem hsla(216, 98%, 52%, 0.25);
}

.sidebar-title {
  font-size: 12px;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}
.design-wrapper {
  margin-top: 20px;
  padding: 0px 15px 0px 15px;
}

.rounded-box {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.rounded-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

/* ------------------------------------------------------------------ */
.Loader {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loader {
  width: 120px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

div.loader span {
  width: 1px;
  height: 100%;
  background-color: #27c5fe;
  animation: loading 1.5s infinite ease-in-out;
}

div.loader span:nth-child(1) {
  animation-delay: 0s;
}

div.loader span:nth-child(2) {
  animation-delay: 0.5s;
}

div.loader span:nth-child(3) {
  animation-delay: 0.3s;
}

div.loader span:nth-child(4) {
  animation-delay: 0.1s;
}
div.loader span:nth-child(5) {
  animation-delay: 0.9s;
}

div.loader span:nth-child(6) {
  animation-delay: 0s;
}

div.loader span:nth-child(7) {
  animation-delay: 0.7s;
}

div.loader span:nth-child(8) {
  animation-delay: 0.1s;
}
div.loader span:nth-child(9) {
  animation-delay: 0.6s;
}

div.loader span:nth-child(10) {
  animation-delay: 0.8s;
}

div.loader span:nth-child(11) {
  animation-delay: 0.4s;
}

div.loader span:nth-child(12) {
  animation-delay: 0s;
}
div.loader span:nth-child(13) {
  animation-delay: 0.9s;
}

div.loader span:nth-child(14) {
  animation-delay: 0.35s;
}

div.loader span:nth-child(15) {
  animation-delay: 0.1s;
}

div.loader span:nth-child(16) {
  animation-delay: 0.5s;
}
div.loader span:nth-child(17) {
  animation-delay: 0.7s;
}

div.loader span:nth-child(18) {
  animation-delay: 0.3s;
}
div.loader span:nth-child(19) {
  animation-delay: 0.9s;
}
div.loader span:nth-child(20) {
  animation-delay: 0.8s;
}
div.loader span:nth-child(21) {
  animation-delay: 0.2s;
}
div.loader span:nth-child(22) {
  animation-delay: 0.7s;
}
div.loader span:nth-child(23) {
  animation-delay: 0.5s;
}
div.loader span:nth-child(24) {
  animation-delay: 0.2s;
}
div.loader span:nth-child(25) {
  animation-delay: 0.2s;
}

@keyframes loading {
  0%,
  100% {
    transform: scaleY(100%);
  }

  50% {
    transform: scaleY(60%);
    background-color: #af3fcf;
  }
  25% {
    transform: scaleY(35%);
    background-color: #27c5fe;
  }
}

/* --------------------------------------------------------------------------------------------------- */
/* 
.loader,
.loader:before,
.loader:after {
  background: #458787;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #458787;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
} */

/* -------------------------------------------------------------------------------------------------- */

/* .rounded-card:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f0f4f7;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  min-width: 200px;
  height: 300px;
  cursor: pointer;
} */

iframe::-webkit-scrollbar {
  display: none;
}
/* =================================Right Drawer Button Styles============================= */

.btn-outlined-drawer {
  background: transparent !important;
  color: var(--wb-website-text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--wb-website-theme-secondary-color) !important;
  border-width: 0.125rem !important;
  display: inline-block !important;
  padding: 0.475rem 1.635rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}

.btn-outlined-drawer:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
  border: 0.125rem solid var(--wb-website-hover-background-color) !important;
}
.btn-text-drawer {
  background: transparent !important;
  color: var(--wb-website-theme-secondary-color) !important;
  display: inline-block !important;
  padding: 0.6rem 1.76rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.btn-text-drawer:hover {
  color: var(--wb-website-text-primary-color) !important;
}
.btn-contained-drawer {
  background: var(--wb-website-theme-secondary-color) !important;
  color: var(--wb-website-text-secondary-color) !important;
  display: inline-block !important;
  padding: 0.6rem 1.76rem !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.btn-contained-drawer:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
}

/* =================================================================================== */

.dark-grey-color {
  color: #747474;
}

.card-heading {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px 0px;
  border-radius: 6px 6px 0px 0px;
}
.card-image-btn {
  width: 100%;
  height: 200px;
}

.card-image-btn-loader {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image-btn-model {
  width: 100%;
  height: 50px;
}

/*  */

#ReactSimpleImageViewer {
  margin-top: 50px;
  z-index: 999;
}

.react-simple-image-viewer__slide {
  margin-top: -50px;
}

.custom-link {
  color: black;
  cursor: pointer;
  font-size: 12px;
}

.custom-link:hover {
  color: #f04756;
  cursor: pointer;
}

.selection-box {
  border: dashed 1px grey;
  width: 190px;
  height: 100px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: pointer;
  background-color: transparent;
  transition: transform 0.4s ease-in-out;
  margin-top: 10px;
}

.selection-box:hover {
  background-color: #e7e7e7;
}

.selection-box-heading {
  transition: transform 0.3s ease-in-out;
}

.selection-box:hover > .selection-box-heading {
  transform: scale(1.1);
}

.left-menu-toggle {
  position: fixed !important;
  transition: transform 4s ease-in-out;
  top: 65px;
  left: -18px;
  z-index: 1200;
}

.left-menu-toggle:hover {
  left: -15px;
}

.edit-able-section {
  border: solid 1px transparent;
  position: relative;
}

.edit-able-section:hover {
  border: solid 1px dodgerblue;
}

.edit-able-section-actions {
  font-weight: bold;
  display: none;
  position: absolute;
  padding: 0px 7px;
  right: 5px;
  top: 5px;
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  border-color: #fff;
  border-width: 2px;
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
  transition-property: border-color, box-shadow, color;
}
.edit-able-section-actions:hover {
  font-weight: bold;
}

.edit-able-section:hover > .edit-able-section-actions {
  display: inline;
  z-index: 99;
}
.edit-able-section-actions-add-section {
  font-weight: bold;
  display: none;
  cursor: pointer;
  position: absolute;
  padding: 0px 7px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  border-color: #fff;
  border-width: 2px;
  box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
  transition-property: border-color, box-shadow, color;
}
.edit-able-section-actions-add-section:hover {
  font-weight: bold;
  color: darkgreen;
}

.edit-able-section:hover > .edit-able-section-actions-add-section {
  display: inline;
  z-index: 99;
}

.edit-able-section-actions-delete {
  cursor: pointer;
}

.edit-able-section-actions-delete:hover {
  cursor: pointer;
  color: #fa2356;
}

/* grid system divs */
.break-able-section div.row:hover {
  outline: blue solid 1px;
}
.break-able-section:hover div.row:hover > .wp-row-block {
  display: inherit;
  justify-content: flex-start;
}

.wp-row-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}

.wp-row-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-row-click:hover {
  cursor: pointer;
  color: red !important;
}
/* Custom Buttons */
.custom-button {
  font-size: var(--wb-main-button-text-font-size);
  padding: 10px 24px;
  border-radius: 50px;
  border: solid 2px red;
  background-color: #fff;
  border-color: #fa2356;
  color: #fa2356;
  padding: 10px 24px;
}

.custom-button:hover {
  background-color: #d21d48;
  border-color: #d21d48;
  color: white;
}
.custom-button:disabled {
  background-color: grey;
  border-color: grey;
  color: white;
}

.main-primary-btn {
  background-color: var(--wb-main-primary-color);
  color: var(--wb-main-primary-text);
}

.main-btn-text {
  font-size: var(--wb-main-button-text-font-size);
  padding: 10px 24px;
}

.main-primary-btn:hover {
  background-color: var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-text-hover);
}
.form-checkbox-color {
  background-color: #e4e4e4;
  width: -webkit-max-content;
  width: max-content;
}
.formsetting-label-parent-div {
  -webkit-user-select: none;
          user-select: none;
  background-color: #e4e4e4;
}
.formsetting-div-color-silver {
  -webkit-user-select: none;
          user-select: none;
  background-color: #d0d0d0;
}

.form-label-checkbox-color- {
  background-color: #e4e4e4;
}

/* Fade in, Fade out */

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

/*  */

.type-card-small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  font-size: larger;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  min-width: 150px;
  height: 100px;
}

.type-card-small:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--wb-main-secondary-color);
  color: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  height: 100px;
  cursor: pointer;
}

.type-card-small-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--wb-main-secondary-color);
  color: #ffffff;
  font-size: larger;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  max-width: 200px;
  min-width: 150px;
  height: 100px;
}

.border-left-blue {
  border-left: solid 2px var(--wb-main-secondary-color);
}
.border-right-blue {
  border-right: solid 2px var(--wb-main-secondary-color);
}

/* Login screen */

.divider:after,
.divider:before {
  content: "";
  flex: 1 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: 110%;
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.wb-dropdown-link {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-align: left;
  cursor: pointer;
  background-color: #fff;
  border: none;
}

.main-dashboard-website-name {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-family: "Roboto", fallback-font;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  letter-spacing: 0.0313em;
}
.main-dashboard-website-status {
  color: #9da3a9;
}
.main-dashboard-website-placeholder {
  opacity: 1;
  cursor: pointer;
}
.main-dashboard-website-placeholder:hover {
  opacity: 0.5;
}

.column-block-list-edit-button {
  visibility: hidden;
}
.column-block-list-edit-button:hover {
  color: #fa2356;
  cursor: pointer;
}

.column-block-list:hover .column-block-list-edit-button {
  visibility: visible;
  margin-top: 3px;
}
.style-toggle-button {
  cursor: pointer;
  visibility: visible;
  /* margin-top: 1px !important; */
}
.style-toggle-button:hover {
  color: #fa2356;
}
.main-dashboard-website-action {
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 78%;
  width: 100%;
  border-radius: 0%;
}
.edit-thumbnail-action {
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 65%;
  width: 100%;
  border-radius: 0%;
}
.page_menu_option {
  position: absolute;
  right: 10px;
  margin-top: 4px;
  padding: 3px;
  z-index: 99;
  background-color: lightslategray;
  border-radius: 50%;
  transition: all 0.1s;
}
.page_menu_option_for_version {
  position: absolute;
  margin-top: 4px;
  right: 10px;

  padding: 3px;
  z-index: 99;
  background-color: lightslategray;
  border-radius: 50%;
  transition: all 0.1s;
}
.version_parent_div {
  position: relative;
}
.version_card {
  border: 1px solid black;
}

/* .page_menu_option:hover {
  padding: 3px;
} */

.main-dashboard-website-action-btn {
  font-size: 10px;
  padding: 10px 24px;
  border-width: 0;
  font-weight: 500;
  line-height: 1.67;
  width: 230px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.188em;
  cursor: pointer;
  border: 2px solid var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-color-hover);
  border-radius: 50px;
  background-color: white;
}
.main-dashboard-website-more-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 11;
  cursor: pointer;
  border: 2px solid var(--wb-main-primary-color-hover);
  color: var(--wb-main-primary-color-hover);
  border-radius: 50px;
}

.main-dashboard-website-more-icon:hover {
  color: white;
  background-color: var(--wb-main-primary-color-hover);
}
.main-dashboard-website-action-btn:hover {
  color: white;
  background-color: var(--wb-main-primary-color-hover);
}

.visible-parent-hover {
  visibility: hidden;
}

.main-dashboard-website-action:hover .visible-parent-hover {
  visibility: visible;
}
.edit-thumbnail-action:hover .visible-parent-hover {
  visibility: visible;
}
.icon-hover-class:hover {
  cursor: pointer !important;
  background-color: #adadad;
}
.helping-category-card {
  display: flex;
  align-content: center;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  cursor: pointer;
}
.helping-category-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.support-ticket-wrapper {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.support-profile {
  display: flex;
}

.upload-button {
  background: rgb(54 117 136 / 8%) !important;
}

.bottom-buttons {
  position: absolute;
  bottom: 50px;
  background-color: white;
  padding-top: 10px;
}

/* wb-box-block */
.break-able-section div.wb-box:hover {
  outline: blue solid 1px;
  position: relative;
}
.break-able-section:hover div.wb-box:hover > .wp-box-block {
  font-family: none;
  display: block;
  position: absolute;
  top: -1px;
  right: 50%;
}

.wp-box-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-box-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-left-radius: 5px;
  color: white !important;
}
.wp-box-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-box-block end */

/* wb-list-block */
/* .break-able-section ul:hover {
  outline: blue solid 1px;
  position: relative;
} */
/* .break-able-section ol:hover {
  outline: blue solid 1px;
  position: relative;
} */
.break-able-section:hover ul:hover > .wp-list-block {
  display: inherit;
}
.break-able-section:hover ol:hover > .wp-list-block {
  display: inherit;
}

.wp-list-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-list-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-list-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */

/* wp-image-block */

.break-able-section img:hover {
  outline: rgb(53, 119, 27) solid 1px;
}

/* wb-image-block end */

/* wb-form-block */
.break-able-section form:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover form:hover > .wp-form-block {
  display: inherit;
}

.wp-form-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: right !important;
  margin-inline: 51px;
}
.wp-form-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-left-radius: 5px;
  color: white !important;
}
.wp-form-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */



/* wb-form-block */
.break-able-section div.wb-iframe:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover div.wb-iframe:hover > .wp-iframe-block {
  display: inherit;
}

.wp-iframe-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: center !important;
}
.wp-iframe-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-iframe-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */


/* wb-form-block */
.break-able-section div.wb-plan-card:hover {
  outline: blue solid 1px;
  position: relative;
}

.break-able-section:hover div.wb-plan-card:hover > .wp-plan-card-block {
  display: inherit;
  position: absolute;
  top: 0%;
  width: 100%;
}

.wp-plan-card-block {
  display: none;
  padding: 0px 0px 0px 0px;
  text-align: start !important;
}
.wp-plan-card-click {
  position: absolute;
  cursor: pointer;
  font-family: none;
  z-index: 99;
  font-weight: 500;
  font-size: 10px !important;
  padding: 2px 4px;
  background-color: blue;
  border-bottom-right-radius: 5px;
  color: white !important;
}
.wp-plan-card-click:hover {
  cursor: pointer;
  color: red !important;
}

/* wb-list-block end */

.wb-preview-div {
  font-family: var(--wb-website-text-font-family);
  font-size: var(--wb-theme-font-size);
  font-weight: var(--wb-theme-font-weight);
  line-height: var(--wb-theme-line-height);
  color: var(--wb-website-text-primary-color);
  background: var(--wb-website-theme-primary-color);
}

/* reset nav colors */

.wb-preview-div a,
.nav-item,
.navbar-light,
.navbar-nav,
.nav-link {
  color: unset;
}

.wb-preview-div h1 {
  font-size: var(--wb-theme-h1-font-size);
  font-weight: var(--wb-theme-h1-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h1-font-family);
}
.wb-preview-div h2 {
  font-size: var(--wb-theme-h2-font-size);
  font-weight: var(--wb-theme-h2-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h2-font-family);
}
.wb-preview-div h3 {
  font-size: var(--wb-theme-h3-font-size);
  font-weight: var(--wb-theme-h3-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h3-font-family);
}
.wb-preview-div h4 {
  font-size: var(--wb-theme-h4-font-size);
  font-weight: var(--wb-theme-h4-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h4-font-family);
}
.wb-preview-div h5 {
  font-size: var(--wb-theme-h5-font-size);
  font-weight: var(--wb-theme-h5-font-weight);
  color: var(--wb-website-text-heading_color);
}
.wb-preview-div h6 {
  font-size: var(--wb-theme-h6-font-size);
  font-weight: var(--wb-theme-h6-font-weight);
  color: var(--wb-website-text-heading_color);
}
.wb-preview-div p,
ul,
ol {
  font-size: var(--wb-theme-p-font-size);
  font-weight: var(--wb-theme-p-font-weight);
}

/* configure placeholder color start*/

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--wb-input-placeholder-color) !important;
}

/* configure placeholder color end */

.wb-preview-div a {
  text-decoration: none;
  display: inline-block;
}
.wb-preview-div img {
  display: unset;
}

/* builder font weights classes */

.wb-fw-100 {
  font-weight: 100 !important;
}
.wb-fw-200 {
  font-weight: 200 !important;
}
.wb-fw-300 {
  font-weight: 300 !important;
}
.wb-fw-400 {
  font-weight: 400 !important;
}
.wb-fw-500 {
  font-weight: 500 !important;
}
.wb-fw-600 {
  font-weight: 600 !important;
}
.wb-fw-700 {
  font-weight: 700 !important;
}
.wb-fw-800 {
  font-weight: 800 !important;
}
.wb-fw-900 {
  font-weight: 900 !important;
}

/* ------------------------------------------------ */

/* media query for handle REM unit on small screen */

@media (max-width: 991.98px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: 12px;
  }
}

/* ------------------------------------------------ */

/* Iframe responsive css */

.wb-preview-div .wb-iframe {
  width: 100%;
}

.wb-preview-div .wb-iframe iframe {
  width: 100%;
}

@media (max-width: 32.813rem) {
  .wb-preview-div .wb-iframe iframe {
    width: 100%;
    height: 15.625rem;
  }
}

/* ---------------------- */

/*--------------------------------------------------------------
# General Utility Classes
--------------------------------------------------------------*/

.font-size-xxs {
  font-size: 0.8rem !important;
}
.font-size-xs {
  font-size: 0.9rem !important;
}
.font-size-sm {
  font-size: 1rem !important;
}
.font-size-md {
  font-size: 1.1rem !important;
}
.font-size-lg {
  font-size: 1.3rem !important;
}
.font-size-xlg {
  font-size: 1.5rem !important;
}
.font-size-xxlg {
  font-size: 1.8rem !important;
}

.font-size-52 {
  font-size: 3.25rem !important;
}

.font-size-42 {
  font-size: 2.625rem !important;
}
.font-size-32 {
  font-size: 2rem !important;
}

/* font families classes */

.font-roboto-class {
  font-family: "Roboto", sans-serif;
}

.font-Montserrat-class {
  font-family: "Montserrat", sans-serif;
}

/* color classes */
.color_bg_000000 {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.color_bg_FFFFFF {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.color_bg_808080 {
  background-color: #808080 !important;
}

.color_bg_FF6900 {
  background-color: #ff6900 !important;
}

.color_bg_FCB900 {
  background-color: #fcb900 !important;
}

.color_bg_7BDCB5 {
  background-color: #7bdcb5 !important;
}

.color_bg_00D084 {
  background-color: #00d084 !important;
}

.color_bg_8ED1FC {
  background-color: #8ed1fc !important;
}

.color_bg_0693E3 {
  background-color: #0693e3 !important;
}

.color_bg_ABB8C3 {
  background-color: #abb8c3 !important;
}

.color_bg_EB144C {
  background-color: #eb144c !important;
}

.color_bg_F78DA7 {
  background-color: #f78da7 !important;
}

.color_bg_DB3E00 {
  background-color: #db3e00 !important;
}

.color_bg_FCCB00 {
  background-color: #fccb00 !important;
}

.color_bg_008B02 {
  background-color: #008b02 !important;
}

.color_bg_006B76 {
  background-color: #006b76 !important;
}

.color_bg_1273DE {
  background-color: #1273de !important;
}

.color_bg_004DCF {
  background-color: #004dcf !important;
  color: #ffffff !important;
}

.color_bg_5300EB {
  background-color: #5300eb !important;
  color: #ffffff !important;
}

.color_bg_EB9694 {
  background-color: #eb9694 !important;
}
.color_bg_CBBC7D {
  background-color: #cbbc7d !important;
}
.color_bg_28634E {
  background-color: #28634e !important;
}
.color_bg_8E214B {
  background-color: #8e214b !important;
}
.color_bg_AB9849 {
  background-color: #ab9849 !important;
}

/* text color classes */

/* color classes */
.color_text_000000 {
  color: #000000 !important;
}

.color_text_FFFFFF {
  color: #ffffff !important;
}

.color_text_808080 {
  color: #808080 !important;
}

.color_text_FF6900 {
  color: #ff6900 !important;
}

.color_text_FCB900 {
  color: #fcb900 !important;
}

.color_text_7BDCB5 {
  color: #7bdcb5 !important;
}

.color_text_00D084 {
  color: #00d084 !important;
}

.color_text_8ED1FC {
  color: #8ed1fc !important;
}

.color_text_0693E3 {
  color: #0693e3 !important;
}

.color_text_ABB8C3 {
  color: #abb8c3 !important;
}

.color_text_EB144C {
  color: #eb144c !important;
}

.color_text_F78DA7 {
  color: #f78da7 !important;
}

.color_text_DB3E00 {
  color: #db3e00 !important;
}

.color_text_FCCB00 {
  color: #fccb00 !important;
}

.color_text_008B02 {
  color: #008b02 !important;
}

.color_text_006B76 {
  color: #006b76 !important;
}

.color_text_1273DE {
  color: #1273de !important;
}

.color_text_004DCF {
  color: #004dcf !important;
}

.color_text_5300EB {
  color: #5300eb !important;
}

.color_text_EB9694 {
  color: #eb9694 !important;
}
.color_text_CBBC7D {
  color: #cbcc7d !important;
}
.color_text_28634E {
  color: #28634e !important;
}
.color_text_8E214B {
  color: #8e214b !important;
}
.color_text_CBBC7D {
  color: #cbcc7d !important;
}
.color_text_AB9849 {
  color: #ab9849 !important;
}

.main_section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main_section_header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* center the image */
/* image size classes for object-fit */
.object-fit-fill {
  object-fit: fill;
}
.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}

/* background size for the background image */

.background-size-auto {
  background-size: auto !important;
}
.background-size-contain {
  background-size: contain !important;
}
.background-size-cover {
  background-size: cover !important;
}

/* background repeat for the background image */

.background-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

.background-repeat-repeat {
  background-repeat: repeat !important;
}

.align-img-center {
  display: block !important; /* if it is not block class will not work */
  margin-left: auto;
  margin-right: auto;
}

.img-width-fluid {
  width: 100%;
  height: auto;
}

.img-height-fluid {
  height: 100%;
  width: auto;
}

/* Margins Classes start */
.wp-mt-0 {
  margin-top: 0rem !important;
}
.wp-mt-1 {
  margin-top: 0.0625rem !important;
}
.wp-mt-2 {
  margin-top: 0.125rem !important;
}
.wp-mt-3 {
  margin-top: 0.1875rem !important;
}
.wp-mt-4 {
  margin-top: 0.25rem !important;
}
.wp-mt-5 {
  margin-top: 0.3125rem !important;
}
.wp-mt-6 {
  margin-top: 0.375rem !important;
}
.wp-mt-7 {
  margin-top: 0.4375rem !important;
}
.wp-mt-8 {
  margin-top: 0.5rem !important;
}
.wp-mt-9 {
  margin-top: 0.5625rem !important;
}
.wp-mt-10 {
  margin-top: 0.625rem !important;
}
.wp-mt-11 {
  margin-top: 0.6875rem !important;
}
.wp-mt-12 {
  margin-top: 0.75rem !important;
}
.wp-mt-13 {
  margin-top: 0.8125rem !important;
}
.wp-mt-14 {
  margin-top: 0.875rem !important;
}
.wp-mt-16 {
  margin-top: 1rem !important;
}
.wp-mt-18 {
  margin-top: 1.125rem !important;
}
.wp-mt-20 {
  margin-top: 1.25rem !important;
}
.wp-mt-24 {
  margin-top: 1.5rem !important;
}
.wp-mt-28 {
  margin-top: 1.75rem !important;
}
.wp-mt-32 {
  margin-top: 2rem !important;
}
.wp-mt-40 {
  margin-top: 2.5rem !important;
}
.wp-mt-45 {
  margin-top: 2.8125rem !important;
}
.wp-mt-50 {
  margin-top: 3.125rem !important;
}
.wp-mt-55 {
  margin-top: 3.4375rem !important;
}
.wp-mt-60 {
  margin-top: 3.75rem !important;
}
.wp-mt-65 {
  margin-top: 4.0625rem !important;
}
.wp-mt-70 {
  margin-top: 4.375rem !important;
}
.wp-mt-75 {
  margin-top: 4.6875rem !important;
}
.wp-mt-80 {
  margin-top: 5rem !important;
}
.wp-mb-0 {
  margin-bottom: 0rem !important;
}
.wp-mb-1 {
  margin-bottom: 0.0625rem !important;
}
.wp-mb-2 {
  margin-bottom: 0.125rem !important;
}
.wp-mb-3 {
  margin-bottom: 0.1875rem !important;
}
.wp-mb-4 {
  margin-bottom: 0.25rem !important;
}
.wp-mb-5 {
  margin-bottom: 0.3125rem !important;
}
.wp-mb-6 {
  margin-bottom: 0.375rem !important;
}
.wp-mb-7 {
  margin-bottom: 0.4375rem !important;
}
.wp-mb-8 {
  margin-bottom: 0.5rem !important;
}
.wp-mb-9 {
  margin-bottom: 0.5625rem !important;
}
.wp-mb-10 {
  margin-bottom: 0.625rem !important;
}
.wp-mb-11 {
  margin-bottom: 0.6875rem !important;
}
.wp-mb-12 {
  margin-bottom: 0.75rem !important;
}
.wp-mb-13 {
  margin-bottom: 0.8125rem !important;
}
.wp-mb-14 {
  margin-bottom: 0.875rem !important;
}
.wp-mb-16 {
  margin-bottom: 1rem !important;
}
.wp-mb-18 {
  margin-bottom: 1.125rem !important;
}
.wp-mb-20 {
  margin-bottom: 1.25rem !important;
}
.wp-mb-24 {
  margin-bottom: 1.5rem !important;
}
.wp-mb-28 {
  margin-bottom: 1.75rem !important;
}
.wp-mb-32 {
  margin-bottom: 2rem !important;
}
.wp-mb-40 {
  margin-bottom: 2.5rem !important;
}
.wp-mb-45 {
  margin-bottom: 2.8125rem !important;
}
.wp-mb-50 {
  margin-bottom: 3.125rem !important;
}
.wp-mb-55 {
  margin-bottom: 3.4375rem !important;
}
.wp-mb-60 {
  margin-bottom: 3.75rem !important;
}
.wp-mb-65 {
  margin-bottom: 4.0625rem !important;
}
.wp-mb-70 {
  margin-bottom: 4.375rem !important;
}
.wp-mb-75 {
  margin-bottom: 4.6875rem !important;
}
.wp-mb-80 {
  margin-bottom: 5rem !important;
}

.wp-ml-0 {
  margin-left: 0rem !important;
}
.wp-ml-1 {
  margin-left: 0.0625rem !important;
}
.wp-ml-2 {
  margin-left: 0.125rem !important;
}
.wp-ml-3 {
  margin-left: 0.1875rem !important;
}
.wp-ml-4 {
  margin-left: 0.25rem !important;
}
.wp-ml-5 {
  margin-left: 0.3125rem !important;
}
.wp-ml-6 {
  margin-left: 0.375rem !important;
}
.wp-ml-7 {
  margin-left: 0.4375rem !important;
}
.wp-ml-8 {
  margin-left: 0.5rem !important;
}
.wp-ml-9 {
  margin-left: 0.5625rem !important;
}
.wp-ml-10 {
  margin-left: 0.625rem !important;
}
.wp-ml-11 {
  margin-left: 0.6875rem !important;
}
.wp-ml-12 {
  margin-left: 0.75rem !important;
}
.wp-ml-13 {
  margin-left: 0.8125rem !important;
}
.wp-ml-14 {
  margin-left: 0.875rem !important;
}
.wp-ml-16 {
  margin-left: 1rem !important;
}
.wp-ml-18 {
  margin-left: 1.125rem !important;
}

.wp-ml-20 {
  margin-left: 1.25rem !important;
}
.wp-ml-24 {
  margin-left: 1.5rem !important;
}
.wp-ml-28 {
  margin-left: 1.75rem !important;
}
.wp-ml-32 {
  margin-left: 2rem !important;
}
.wp-ml-40 {
  margin-left: 2.5rem !important;
}
.wp-ml-45 {
  margin-left: 2.8125rem !important;
}
.wp-ml-50 {
  margin-left: 3.125rem !important;
}
.wp-ml-55 {
  margin-left: 3.4375rem !important;
}
.wp-ml-60 {
  margin-left: 3.75rem !important;
}
.wp-ml-65 {
  margin-left: 4.0625rem !important;
}
.wp-ml-70 {
  margin-left: 4.375rem !important;
}
.wp-ml-75 {
  margin-left: 4.6875rem !important;
}
.wp-ml-80 {
  margin-left: 5rem !important;
}
.wp-mr-0 {
  margin-right: 0rem !important;
}
.wp-mr-1 {
  margin-right: 0.0625rem !important;
}
.wp-mr-2 {
  margin-right: 0.125rem !important;
}
.wp-mr-3 {
  margin-right: 0.1875rem !important;
}
.wp-mr-4 {
  margin-right: 0.25rem !important;
}
.wp-mr-5 {
  margin-right: 0.3125rem !important;
}
.wp-mr-6 {
  margin-right: 0.375rem !important;
}
.wp-mr-7 {
  margin-right: 0.4375rem !important;
}
.wp-mr-8 {
  margin-right: 0.5rem !important;
}
.wp-mr-9 {
  margin-right: 0.5625rem !important;
}
.wp-mr-10 {
  margin-right: 0.625rem !important;
}
.wp-mr-11 {
  margin-right: 0.6875rem !important;
}
.wp-mr-12 {
  margin-right: 0.75rem !important;
}
.wp-mr-13 {
  margin-right: 0.8125rem !important;
}
.wp-mr-14 {
  margin-right: 0.875rem !important;
}
.wp-mr-16 {
  margin-right: 1rem !important;
}
.wp-mr-18 {
  margin-right: 1.125rem !important;
}
.wp-mr-20 {
  margin-right: 1.25rem !important;
}
.wp-mr-24 {
  margin-right: 1.5rem !important;
}
.wp-mr-28 {
  margin-right: 1.75rem !important;
}
.wp-mr-32 {
  margin-right: 2rem !important;
}
.wp-mr-40 {
  margin-right: 2.5rem !important;
}
.wp-mr-45 {
  margin-right: 2.8125rem !important;
}
.wp-mr-50 {
  margin-right: 3.125rem !important;
}
.wp-mr-55 {
  margin-right: 3.4375rem !important;
}
.wp-mr-60 {
  margin-right: 3.75rem !important;
}
.wp-mr-65 {
  margin-right: 4.0625rem !important;
}
.wp-mr-70 {
  margin-right: 4.375rem !important;
}
.wp-mr-75 {
  margin-right: 4.6875rem !important;
}
.wp-mr-80 {
  margin-right: 5rem !important;
}

/* Margins Classes End*/

/* Paddings Classes Start*/
.wp-pt-0 {
  padding-top: 0rem !important;
}
.wp-pt-1 {
  padding-top: 0.0625rem !important;
}
.wp-pt-2 {
  padding-top: 0.125rem !important;
}
.wp-pt-3 {
  padding-top: 0.1875rem !important;
}
.wp-pt-4 {
  padding-top: 0.25rem !important;
}
.wp-pt-5 {
  padding-top: 0.3125rem !important;
}
.wp-pt-6 {
  padding-top: 0.375rem !important;
}
.wp-pt-7 {
  padding-top: 0.4375rem !important;
}
.wp-pt-8 {
  padding-top: 0.5rem !important;
}
.wp-pt-9 {
  padding-top: 0.5625rem !important;
}
.wp-pt-10 {
  padding-top: 0.625rem !important;
}
.wp-pt-11 {
  padding-top: 0.6875rem !important;
}
.wp-pt-12 {
  padding-top: 0.75rem !important;
}
.wp-pt-13 {
  padding-top: 0.8125rem !important;
}
.wp-pt-14 {
  padding-top: 0.875rem !important;
}
.wp-pt-16 {
  padding-top: 1rem !important;
}
.wp-pt-18 {
  padding-top: 1.125rem !important;
}
.wp-pt-20 {
  padding-top: 1.25rem !important;
}
.wp-pt-24 {
  padding-top: 1.5rem !important;
}
.wp-pt-28 {
  padding-top: 1.75rem !important;
}
.wp-pt-32 {
  padding-top: 2rem !important;
}
.wp-pt-40 {
  padding-top: 2.5rem !important;
}
.wp-pt-45 {
  padding-top: 2.8125rem !important;
}
.wp-pt-50 {
  padding-top: 3.125rem !important;
}
.wp-pt-55 {
  padding-top: 3.4375rem !important;
}
.wp-pt-60 {
  padding-top: 3.75rem !important;
}
.wp-pt-65 {
  padding-top: 4.0625rem !important;
}
.wp-pt-70 {
  padding-top: 4.375rem !important;
}
.wp-pt-75 {
  padding-top: 4.6875rem !important;
}
.wp-pt-80 {
  padding-top: 5rem !important;
}
.wp-pb-0 {
  padding-bottom: 0rem !important;
}
.wp-pb-1 {
  padding-bottom: 0.0625rem !important;
}
.wp-pb-2 {
  padding-bottom: 0.125rem !important;
}
.wp-pb-3 {
  padding-bottom: 0.1875rem !important;
}
.wp-pb-4 {
  padding-bottom: 0.25rem !important;
}
.wp-pb-5 {
  padding-bottom: 0.3125rem !important;
}
.wp-pb-6 {
  padding-bottom: 0.375rem !important;
}
.wp-pb-7 {
  padding-bottom: 0.4375rem !important;
}
.wp-pb-8 {
  padding-bottom: 0.5rem !important;
}
.wp-pb-9 {
  padding-bottom: 0.5625rem !important;
}
.wp-pb-10 {
  padding-bottom: 0.625rem !important;
}
.wp-pb-11 {
  padding-bottom: 0.6875rem !important;
}
.wp-pb-12 {
  padding-bottom: 0.75rem !important;
}
.wp-pb-13 {
  padding-bottom: 0.8125rem !important;
}
.wp-pb-14 {
  padding-bottom: 0.875rem !important;
}
.wp-pb-16 {
  padding-bottom: 1rem !important;
}
.wp-pb-18 {
  padding-bottom: 1.125rem !important;
}
.wp-pb-20 {
  padding-bottom: 1.25rem !important;
}
.wp-pb-24 {
  padding-bottom: 1.5rem !important;
}
.wp-pb-28 {
  padding-bottom: 1.75rem !important;
}
.wp-pb-32 {
  padding-bottom: 2rem !important;
}
.wp-pb-40 {
  padding-bottom: 2.5rem !important;
}
.wp-pb-45 {
  padding-bottom: 2.8125rem !important;
}
.wp-pb-50 {
  padding-bottom: 3.125rem !important;
}
.wp-pb-55 {
  padding-bottom: 3.4375rem !important;
}
.wp-pb-60 {
  padding-bottom: 3.75rem !important;
}
.wp-pb-65 {
  padding-bottom: 4.0625rem !important;
}
.wp-pb-70 {
  padding-bottom: 4.375rem !important;
}
.wp-pb-75 {
  padding-bottom: 4.6875rem !important;
}
.wp-pb-80 {
  padding-bottom: 5rem !important;
}

.wp-pl-0 {
  padding-left: 0rem !important;
}
.wp-pl-1 {
  padding-left: 0.0625rem !important;
}
.wp-pl-2 {
  padding-left: 0.125rem !important;
}
.wp-pl-3 {
  padding-left: 0.1875rem !important;
}
.wp-pl-4 {
  padding-left: 0.25rem !important;
}
.wp-pl-5 {
  padding-left: 0.3125rem !important;
}
.wp-pl-6 {
  padding-left: 0.375rem !important;
}
.wp-pl-7 {
  padding-left: 0.4375rem !important;
}
.wp-pl-8 {
  padding-left: 0.5rem !important;
}
.wp-pl-9 {
  padding-left: 0.5625rem !important;
}
.wp-pl-10 {
  padding-left: 0.625rem !important;
}
.wp-pl-11 {
  padding-left: 0.6875rem !important;
}
.wp-pl-12 {
  padding-left: 0.75rem !important;
}
.wp-pl-13 {
  padding-left: 0.8125rem !important;
}
.wp-pl-14 {
  padding-left: 0.875rem !important;
}
.wp-pl-16 {
  padding-left: 1rem !important;
}
.wp-pl-18 {
  padding-left: 1.125rem !important;
}

.wp-pl-20 {
  padding-left: 1.25rem !important;
}
.wp-pl-24 {
  padding-left: 1.5rem !important;
}
.wp-pl-28 {
  padding-left: 1.75rem !important;
}
.wp-pl-32 {
  padding-left: 2rem !important;
}
.wp-pl-40 {
  padding-left: 2.5rem !important;
}
.wp-pl-45 {
  padding-left: 2.8125rem !important;
}
.wp-pl-50 {
  padding-left: 3.125rem !important;
}
.wp-pl-55 {
  padding-left: 3.4375rem !important;
}
.wp-pl-60 {
  padding-left: 3.75rem !important;
}
.wp-pl-65 {
  padding-left: 4.0625rem !important;
}
.wp-pl-70 {
  padding-left: 4.375rem !important;
}
.wp-pl-75 {
  padding-left: 4.6875rem !important;
}
.wp-pl-80 {
  padding-left: 5rem !important;
}
.wp-pr-0 {
  padding-right: 0rem !important;
}
.wp-pr-1 {
  padding-right: 0.0625rem !important;
}
.wp-pr-2 {
  padding-right: 0.125rem !important;
}
.wp-pr-3 {
  padding-right: 0.1875rem !important;
}
.wp-pr-4 {
  padding-right: 0.25rem !important;
}
.wp-pr-5 {
  padding-right: 0.3125rem !important;
}
.wp-pr-6 {
  padding-right: 0.375rem !important;
}
.wp-pr-7 {
  padding-right: 0.4375rem !important;
}
.wp-pr-8 {
  padding-right: 0.5rem !important;
}
.wp-pr-9 {
  padding-right: 0.5625rem !important;
}
.wp-pr-10 {
  padding-right: 0.625rem !important;
}
.wp-pr-11 {
  padding-right: 0.6875rem !important;
}
.wp-pr-12 {
  padding-right: 0.75rem !important;
}
.wp-pr-13 {
  padding-right: 0.8125rem !important;
}
.wp-pr-14 {
  padding-right: 0.875rem !important;
}
.wp-pr-16 {
  padding-right: 1rem !important;
}
.wp-pr-18 {
  padding-right: 1.125rem !important;
}
.wp-pr-20 {
  padding-right: 1.25rem !important;
}
.wp-pr-24 {
  padding-right: 1.5rem !important;
}
.wp-pr-28 {
  padding-right: 1.75rem !important;
}
.wp-pr-32 {
  padding-right: 2rem !important;
}
.wp-pr-40 {
  padding-right: 2.5rem !important;
}
.wp-pr-45 {
  padding-right: 2.8125rem !important;
}
.wp-pr-50 {
  padding-right: 3.125rem !important;
}
.wp-pr-55 {
  padding-right: 3.4375rem !important;
}
.wp-pr-60 {
  padding-right: 3.75rem !important;
}
.wp-pr-65 {
  padding-right: 4.0625rem !important;
}
.wp-pr-70 {
  padding-right: 4.375rem !important;
}
.wp-pr-75 {
  padding-right: 4.6875rem !important;
}
.wp-pr-80 {
  padding-right: 5rem !important;
}

/* Paddings Classes End*/

/* border-width classes for button */
/* .wp-border-0 {
  border-width: 0 !important;
}
.wp-border-1 {
  border-width: 0.0625rem !important;
}
.wp-border-2 {
  border-width: 0.125rem !important;
}
.wp-border-3 {
  border-width: 0.1875rem !important;
}
.wp-border-4 {
  border-width: 0.25rem !important;
}
.wp-border-5 {
  border-width: 0.3125rem !important;
}
.wp-border-6 {
  border-width: 0.375rem !important;
}
.wp-border-7 {
  border-width: 0.4375rem !important;
}
.wp-border-8 {
  border-width: 0.5rem !important;
}
.wp-border-9 {
  border-width: 0.5625rem !important;
}
.wp-border-10 {
  border-width: 0.625rem !important;
} */
/* border-width classes for button ended */

/* border-width classes for anchor */
.wp-border-0 {
  border-style: solid;
  border-width: 0 !important;
}
.wp-border-1 {
  border-style: solid;
  border-width: 0.0625rem !important;
}
.wp-border-2 {
  border-style: solid;
  border-width: 0.125rem !important;
}
.wp-border-3 {
  border-style: solid;
  border-width: 0.1875rem !important;
}
.wp-border-4 {
  border-style: solid;
  border-width: 0.25rem !important;
}
.wp-border-5 {
  border-style: solid;
  border-width: 0.3125rem !important;
}
.wp-border-6 {
  border-style: solid;
  border-width: 0.375rem !important;
}
.wp-border-7 {
  border-style: solid;
  border-width: 0.4375rem !important;
}
.wp-border-8 {
  border-style: solid;
  border-width: 0.5rem !important;
}
.wp-border-9 {
  border-style: solid;
  border-width: 0.5625rem !important;
}
.wp-border-10 {
  border-style: solid;
  border-width: 0.625rem !important;
}
/* border-width classes for anchor ended */

/* background-position classes for image */
.wp-bp-tl {
  background-position: top left !important;
  background-size: auto !important;
}
.wp-bp-tc {
  background-position: top center !important;
  background-size: auto !important;
}
.wp-bp-tr {
  background-position: top right !important;
  background-size: auto !important;
}
.wp-bp-cl {
  background-position: center left !important;
  background-size: auto !important;
}
.wp-bp-cc {
  background-position: center center !important;
  background-size: auto !important;
}
.wp-bp-cr {
  background-position: center right !important;
  background-size: auto !important;
}
.wp-bp-bl {
  background-position: bottom left !important;
  background-size: auto !important;
}
.wp-bp-bc {
  background-position: bottom center !important;
  background-size: auto !important;
}
.wp-bp-br {
  background-position: bottom right !important;
  background-size: auto !important;
}

/* background-position classes for image ended */

/* ============ Animation for Drawer ====== */

#LeftDrawer {
  box-shadow: 3px 3px 6px rgba(145, 145, 145, 0.619);
  transition: 0.3s ease-in-out;
  z-index: auto;
  -webkit-transition: ease-in-out 0.3s;
}

.section-item:hover {
  transform: translateY(-3px);
  box-shadow: 3px 3px 6px rgba(203, 199, 199, 0.619);
  transition: ease-in-out 0.3s;
}

/* Search Icon Left Drawer CSS */

.search-box {
  position: absolute;
  right: 46px;
  background: #f8f8f8;
  height: 30px;
  border-radius: 30px;
  display: flex;
  transition: 0.3s ease-in-out;
}
/* .search-box:hover > input{
  width: 160px;
  padding: 0.4rem 0.8rem;
} */
.input {
  display: none;
}
.search-box-width {
  width: 140px;
  display: block;
  padding: 0rem 0.8rem;
  border: none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  transition: 0.3s ease-in-out;
  line-height: 30px;
  color: #666666;
}

/* .search-box:hover > .icon{
  background: #f1f1f1;
} */
.icon:hover {
  background: #f1f1f1;
}

.icon-delete:hover i {
  color: #f23105 !important;
}
.icon i {
  color: #666666;
}
/* .searchInput {
  border:none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  align-content: center;
  transition: 0.3s ease;
  line-height: 30px;
  color: #666666;
} */
.icon {
  float: right;
  width: 30px;
  font-size: 1rem;
  height: 30px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}
.element-card {
  width: 100px;
  font-size: 1rem;
  height: 100px;
  border-radius: 0.5rem;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}
.element-card i {
  color: #666666;
  font-size: 2rem;
}

/* Button variant classes */

.wb-btn-outlined {
  background: transparent !important;
  color: var(--wb-website-text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--wb-website-theme-secondary-color) !important;
  border-width: 0.125rem !important;
}

.wb-btn-outlined:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
  border: 0.125rem solid var(--wb-website-hover-background-color) !important;
}
.wb-btn-text {
  background: transparent !important;
  color: var(--wb-website-theme-secondary-color) !important;
}

.wb-btn-text:hover {
  color: var(--wb-website-text-primary-color) !important;
}
.wb-btn-contained {
  background: var(--wb-website-theme-secondary-color) !important;
  color: var(--wb-website-text-secondary-color) !important;
}

.wb-btn-contained:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
}


/* Width Classses */

.wb-width-10{
  width: 10%!important;
}
.wb-width-25{
  width: 25%!important;
}
.wb-width-50{
  width: 50%!important;
}
.wb-width-100{
  width: 100%!important;
}

/* letter Spacing */


.wb-ls-1{
  letter-spacing: 1px;
}
.wb-ls-2{
  letter-spacing: 2px;
}
.wb-ls-3{
  letter-spacing: 3px;
}
.wb-ls-5{
  letter-spacing: 5px;
}
.wb-ls-10{
  letter-spacing: 10px;
}
.wb-ls-20{
  letter-spacing: 20px;
}


/*  Calender CSS */

.calendar-body li {
  width: calc(100% / 7);
  font-size: 1.07rem;
  color: #414141;
}

.calendar-body .wb-calendar-dates li {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.wb-calendar-dates li.inactive {
  color: #aaa;
}

.wb-calendar-dates li.active {
  color: #fff;
}

.wb-calendar-dates li::before {
  position: absolute;
  content: "";
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.wb-calendar-dates li.active::before {
  background: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color);
}

.calendar-time-slots .slot span:hover{
  background-color: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color) !important;
}
.calendar-time-slots .slot .active{
  background-color: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color)!important;
}

.calendar-container p {
  color: #414141;
}



/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Website editor styles */

.wb-preview-div {
  min-height: 75vh;
  overflow-y: scroll;
  margin: 60px 20px;
  padding-bottom: 20px;
}

.wb-preview-div .wb-editor-block {
  border: solid 1px transparent;
}

.wb-preview-div .wb-editor-block:hover {
  border: solid 1px #c4c4c4;
}

.wb-container-max-1100 {
  max-width: 1100px !important;
}

.wb-custom-search-input {
  height: 30px;
}

.wb-left-menu-wrapper {
  padding-bottom: 100px;
}

.wb-header-download {
  height: 50px;
  padding: 19px 27px;
  background: #fa2356;
  font-size: 14px;
  color: #fff;
  background-color: #fa2356;
  border-width: 2px;
  cursor: pointer;
}
.wb-header-download:hover {
  height: 50px;
  padding: 19px 27px;
  font-size: 14px;
  color: #fff;
  background-color: #d21d48;
  border-color: #d21d48;
  border-width: 2px;
}

.force-disabled {
  cursor: not-allowed !important;
  background: #dddddd !important;
  pointer-events: none !important;
}

.wb-header-preview {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 18px;
  color: #000;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  line-height: 1.2em;
  cursor: pointer;
  text-decoration: none;
}
.exit-builder-style {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 18px;
  color: #000;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  line-height: 1.2em;
  cursor: pointer;
  text-decoration: none;
  color: #fa2356;
  background-color: initial;
  background-color: #fa23560d;
}
.wb-header-preview:hover {
  color: #022859;
  background-color: initial;
  /* background-color: #fa23560d; */
  background-color: #02285912;
}
.wb-header-preview:active {
  color: #022859;
  border-bottom: solid 2px #022859;
}
.download-btn:hover {
  color: #022859;
  background-color: initial;
  background-color: #02285912;
}

.wb-save .spinner-border {
  height: 20px;
  width: 20px;
  border-width: 2px;
  vertical-align: middle;
  color: #022859;
}

.wb-header-theme {
  border-width: 0;
  border-radius: 50%;
}

.wb-menu-icon:hover {
  background: #02285912;
}
.wb-menu-icon-page-section {
  height: 42px;
  margin: 0px 5px 5px 8px;
  border-radius: 4px;
  border: 1px solid #dfe5eb;
  color: #3b4057;
  display: flex;
  align-items: center;

  justify-content: space-between;
  background: transparent;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.font-style {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px !important;
}
.wb-menu-icon-page-section:hover {
  background-color: #d6e6fe;
  border-color: #3485fe;
}

.section-option-icon {
  padding: 3px;
  cursor: pointer;
  border: 1px solid #3485fe;
  border-radius: 2rem;
  visibility: hidden;
}
.section-option-icon:hover {
  background-color: #3485fec8;
  color: #fff;
}

.wb-menu-icon-page-section:hover .section-option-icon {
  visibility: visible;
}

.wb-image-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  overflow-y: auto;
  overflow-x: hidden;
  align-content: flex-start;
}

.wb-image {
  margin: 0.5rem 0.25rem 0rem;
  position: relative;
}
.wb-image:hover img {
  opacity: 0.8;
}
.image-icon {
  width: 20px;
  position: absolute;
  right: 2%;
  font-size: 0.6rem;
  height: 20px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.image-icon:hover {
  background: #f1f1f1;
}
.image-icon i {
  color: #666666;
}

.wb-image .image-icon {
  visibility: hidden;
}

.wb-image:hover .image-icon {
  visibility: visible;
}
.add-section-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.section-image .add-section-button {
  visibility: hidden;
}
.section-image:hover .add-section-button {
  visibility: visible;
}
.section-image .image-icon {
  visibility: hidden;
}

.section-image:hover .image-icon {
  visibility: visible;
}

.funnel-card {
  background-color: #fff;
  height: 150px !important;
  width: 240px !important;
  border-radius: 8px;
  box-shadow:  rgba(64,60,67,0.16) 0 1px 5px 1px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.funnel-circle{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #023373;
  background-color: #e8eaec;
  /* border: 1px solid #023373; */
  font-size: 16px;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
  white-space: nowrap;
}

.transition-class {
  transition: 0.3s ease-in-out;
}

.wb-menu-icon-selected {
  background: #02285912;
}
.wb-add-section-modal-selected {
  background: #02285912;
}
.wb-add-section-modal:hover {
  background: #02285912;
}

.wb-container-max-1100 {
  max-width: 1100px !important;
}

.wb-menu-icon-row {
  width: 40px;
  float: left;
  margin-right: 10px;
  padding-bottom: 150px;
}

.wb-custom-search-input {
  height: 30px;
}

.wb-left-menu-wrapper {
  padding-bottom: 100px;
}

.wb-header-download {
  height: 50px;
  padding: 19px 27px;
  background: #fa2356;
  font-size: 14px;
  color: #fff;
  background-color: #fa2356;
  border-width: 2px;
  cursor: pointer;
}
.wb-header-download:hover {
  height: 50px;
  padding: 19px 27px;
  font-size: 14px;
  color: #fff;
  background-color: #d21d48;
  border-color: #d21d48;
  border-width: 2px;
}

/* Theme selection tab styling */

.wb-style-selection-tab-left-drawer {
  height: 40px;
  color: #666666;
  overflow: hidden;
  font-size: 0.8rem;
  text-decoration: none;
  width: 235px;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  right: 0%;
  padding: 0.7rem 0.8rem;
  border-bottom: solid 2px #bdbdbd;
}
.wb-style-selection-tab {
  color: #022859;
  vertical-align: middle;
  background-color: initial;
  overflow: hidden;
  font-size: 14px;
  border-radius: 0;
  transition-property: border-color, color;
  cursor: pointer;
  text-decoration: none;
  padding: 2px 0px;
  border-bottom: solid 2px transparent;
}

.wb-style-selection-tab:hover {
  color: #022859;
  background-color: initial;
  background-color: #02285912;
}

.wb-style-selection-tab:active {
  color: #022859;
  border-bottom: solid 2px #022859;
}

.wb-style-selection-tab-active {
  color: #022859;
  border-bottom: solid 2px #022859;
  background-color: #02285912;
}

.wb-icons-box {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dee2e6;
}

.wb-icons-box-active {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #dee2e6;
  background-color: #02285912;
}

.wb-icons-box:hover {
  background-color: #02285912;
}

.custom-icon-select {
  border: none;
  padding: 2px 8px;
  background-image: url("https://api.iconify.design/gridicons/dropdown.svg");
  background-position: right; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-label {
  color: black;
  font-size: 12px;
}
.form-control-sm-custom {
  height: calc(1.5em + 0.3rem + 2px);
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.custom-icon-font-family {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/bx/bx-font-family.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-icon-font-size {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/bx/bx-font-size.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.custom-icon-line-height {
  border: none;
  padding-left: 30px;
  background-image: url("https://api.iconify.design/ri/line-height.svg");
  background-position: 8% 50%; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  border: 1px solid #dee2e6;
}

.extra-small-btn {
  font-size: x-small;
  padding: 3px 8px;
}
/* Custom classes */
.icon-container {
  border-radius: 1px;
  border: solid 1px transparent;
}
.icon-container:hover {
  background-color: #fa23560d;
  cursor: pointer;
}
.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* custom grid system for only editor */

.wb-editor-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}

.wb-editor-col-2 {
  flex: 0 0 auto;
  width: 16.66%;
}

.wb-editor-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.wb-editor-col-4 {
  flex: 0 0 auto;
  width: 33%;
}

.wb-editor-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

/* Media files Drop Zone CSS */
.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}
.image-name-size {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  color: gray;
  font-size: 12px;
}

/* Gallery */

.search-box-gallery {
  position: absolute;
  right: 8px;
  top: 6px;
  background: #f8f8f8;
  height: 30px;
  border-radius: 30px;
  display: flex;
  transition: 0.3s ease-in-out;
}
/* .search-box:hover > input{
  width: 160px;
  padding: 0.4rem 0.8rem;
} */
.input {
  display: none;
}
.search-box-gallery-width {
  width: 150px;
  display: block;
  padding: 0rem 0.8rem;
  border: none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  transition: 0.3s ease-in-out;
  line-height: 30px;
  color: #666666;
}

/* Image Editor */

.image-editor-button {
  padding: 2px 8px 2px;
  cursor: pointer;
  color: gray;
}
.image-editor-button-active {
  border-bottom: 2px solid;
  border-color: #022859;
  color: #022859 !important;
  background-color: #02285912;
}


/* Row Setting */

.row-layout-option{
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Section Setting */

.section-name:disabled{
background-color: #fff !important;
}


/* Right Menu Wrappeer */

.right-menu-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 2000;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
}
.edit-button-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.iframe-edit-button-wrapper {
  background-color: #fff;
  position: absolute;
  z-index: 1200;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-navlist-button {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}

.wb-list-setting-button{
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-layout-setting-setting-button{
  background-color: #fff;
  position: absolute;
  z-index: 10;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
.wb-box-setting-setting-button{
  background-color: #fff;
  position: absolute;
  left: 50%;
  z-index: 10;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}
/* =========================== Choose Template Modal ================== */

.create-project-card {
  box-shadow: 0px 5px 5px rgb(0 0 0 / 7%);
  padding: 8px;
}
.create-project-card:hover {
  /* transform: translateY(-2%); */
  transition: ease-in-out 0.3s;
}
.create-project-card .create-project-edit-button {
  position: relative;
  height: 100%;
  top: -100%;
  background-color: rgba(22, 45, 61, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-project-card .create-project-edit-button {
  opacity: 0;
}
.create-project-card:hover .create-project-edit-button {
  opacity: 1;
}

/* ===========================From Setting 3rd party tab ================== */
.third-party-box {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.third-party-box-active {
  border: 1px solid #d21d48;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin: 16px 0px;
  height: 55px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
/* ===========================Animation================== */

.animation-box-LTR {
  animation: move-left 0.3s ease-in-out forwards;
}

@keyframes move-left {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.tox-toolbar__overflow {
  animation: fade-in 0.15s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}


/* // Ai Assistant */

.example-box{
  background-color: #f7f7f8;
  padding: 12px;
  width: 70%;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
}

.microphone {
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.microphone.active {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
      background-color: #4286f42f;
  }
  to {
      background-color: #4c9baf0e;
  }
}

/*
 * CKEditor 5 (v31.1.0) content styles.
 * Generated on Mon, 17 Jan 2022 14:15:25 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imagecaption.css */
.wb-preview-div .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-block-align-left,
.wb-preview-div .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-align-left,
.wb-preview-div .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div p + .image-style-align-left,
.wb-preview-div p + .image-style-align-right,
.wb-preview-div p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-inline.image-style-align-left,
.wb-preview-div .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.wb-preview-div .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-font/theme/fontsize.css */
.wb-preview-div .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.wb-preview-div .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.wb-preview-div .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.wb-preview-div .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-code-block/theme/codeblock.css */
.wb-preview-div pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.wb-preview-div pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-image/theme/image.css */
.wb-preview-div .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.wb-preview-div .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.wb-preview-div .image-inline {
  /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.wb-preview-div .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.wb-preview-div .image-inline picture,
.wb-preview-div .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.wb-preview-div .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.wb-preview-div .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.wb-preview-div .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.wb-preview-div .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.wb-preview-div blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.wb-preview-div[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.wb-preview-div code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-table/theme/tablecaption.css */
.wb-preview-div .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div .table {
  margin: 0.9em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div .table table td,
.wb-preview-div .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.wb-preview-div[dir="ltr"] .table th {
  text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.wb-preview-div .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.wb-preview-div .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.wb-preview-div .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.wb-preview-div .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.wb-preview-div .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-language/theme/language.css */
.wb-preview-div span[lang] {
  font-style: italic;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.wb-preview-div hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-mention/theme/mention.css */
.wb-preview-div .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .wb-preview-div .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .wb-preview-div .page-break::after {
    display: none;
  }
}


.wb-preview-div {
  font-family: var(--wb-website-text-font-family);
  font-size: var(--wb-theme-font-size);
  font-weight: var(--wb-theme-font-weight);
  line-height: var(--wb-theme-line-height);
  color: var(--wb-website-text-primary-color);
  background: var(--wb-website-theme-primary-color);
}

/* reset nav colors */

.wb-preview-div a,
.nav-item,
.navbar-light,
.navbar-nav,
.nav-link {
  color: unset;
}

.wb-preview-div h1 {
  font-size: var(--wb-theme-h1-font-size);
  font-weight: var(--wb-theme-h1-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h1-font-family);
}
.wb-preview-div h2 {
  font-size: var(--wb-theme-h2-font-size);
  font-weight: var(--wb-theme-h2-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h2-font-family);
}
.wb-preview-div h3 {
  font-size: var(--wb-theme-h3-font-size);
  font-weight: var(--wb-theme-h3-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h3-font-family);
}
.wb-preview-div h4 {
  font-size: var(--wb-theme-h4-font-size);
  font-weight: var(--wb-theme-h4-font-weight);
  color: var(--wb-website-text-heading_color);
  font-family: var(--wb-theme-h4-font-family);
}
.wb-preview-div h5 {
  font-size: var(--wb-theme-h5-font-size);
  font-weight: var(--wb-theme-h5-font-weight);
  color: var(--wb-website-text-heading_color);
}
.wb-preview-div h6 {
  font-size: var(--wb-theme-h6-font-size);
  font-weight: var(--wb-theme-h6-font-weight);
  color: var(--wb-website-text-heading_color);
}
.wb-preview-div p,
ul,
ol {
  font-size: var(--wb-theme-p-font-size);
  font-weight: var(--wb-theme-p-font-weight);
}

/* configure placeholder color start*/

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--wb-input-placeholder-color) !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--wb-input-placeholder-color) !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--wb-input-placeholder-color) !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--wb-input-placeholder-color) !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--wb-input-placeholder-color) !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--wb-input-placeholder-color) !important;
}

/* configure placeholder color end */

.wb-preview-div a {
  text-decoration: none;
  display: inline-block;
}
.wb-preview-div img {
  display: unset;
}

/* builder font weights classes */

.wb-fw-100 {
  font-weight: 100 !important;
}
.wb-fw-200 {
  font-weight: 200 !important;
}
.wb-fw-300 {
  font-weight: 300 !important;
}
.wb-fw-400 {
  font-weight: 400 !important;
}
.wb-fw-500 {
  font-weight: 500 !important;
}
.wb-fw-600 {
  font-weight: 600 !important;
}
.wb-fw-700 {
  font-weight: 700 !important;
}
.wb-fw-800 {
  font-weight: 800 !important;
}
.wb-fw-900 {
  font-weight: 900 !important;
}

/* ------------------------------------------------ */

/* media query for handle REM unit on small screen */

@media (max-width: 991.98px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: 12px;
  }
}

/* ------------------------------------------------ */

/* Iframe responsive css */

.wb-preview-div .wb-iframe {
  width: 100%;
}

.wb-preview-div .wb-iframe iframe {
  width: 100%;
}

@media (max-width: 32.813rem) {
  .wb-preview-div .wb-iframe iframe {
    width: 100%;
    height: 15.625rem;
  }
}

/* ---------------------- */

/*--------------------------------------------------------------
# General Utility Classes
--------------------------------------------------------------*/

.font-size-xxs {
  font-size: 0.8rem !important;
}
.font-size-xs {
  font-size: 0.9rem !important;
}
.font-size-sm {
  font-size: 1rem !important;
}
.font-size-md {
  font-size: 1.1rem !important;
}
.font-size-lg {
  font-size: 1.3rem !important;
}
.font-size-xlg {
  font-size: 1.5rem !important;
}
.font-size-xxlg {
  font-size: 1.8rem !important;
}

.font-size-52 {
  font-size: 3.25rem !important;
}

.font-size-42 {
  font-size: 2.625rem !important;
}
.font-size-32 {
  font-size: 2rem !important;
}

/* font families classes */

.font-roboto-class {
  font-family: "Roboto", sans-serif;
}

.font-Montserrat-class {
  font-family: "Montserrat", sans-serif;
}

/* color classes */
.color_bg_000000 {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.color_bg_FFFFFF {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.color_bg_808080 {
  background-color: #808080 !important;
}

.color_bg_FF6900 {
  background-color: #ff6900 !important;
}

.color_bg_FCB900 {
  background-color: #fcb900 !important;
}

.color_bg_7BDCB5 {
  background-color: #7bdcb5 !important;
}

.color_bg_00D084 {
  background-color: #00d084 !important;
}

.color_bg_8ED1FC {
  background-color: #8ed1fc !important;
}

.color_bg_0693E3 {
  background-color: #0693e3 !important;
}

.color_bg_ABB8C3 {
  background-color: #abb8c3 !important;
}

.color_bg_EB144C {
  background-color: #eb144c !important;
}

.color_bg_F78DA7 {
  background-color: #f78da7 !important;
}

.color_bg_DB3E00 {
  background-color: #db3e00 !important;
}

.color_bg_FCCB00 {
  background-color: #fccb00 !important;
}

.color_bg_008B02 {
  background-color: #008b02 !important;
}

.color_bg_006B76 {
  background-color: #006b76 !important;
}

.color_bg_1273DE {
  background-color: #1273de !important;
}

.color_bg_004DCF {
  background-color: #004dcf !important;
  color: #ffffff !important;
}

.color_bg_5300EB {
  background-color: #5300eb !important;
  color: #ffffff !important;
}

.color_bg_EB9694 {
  background-color: #eb9694 !important;
}
.color_bg_CBBC7D {
  background-color: #cbbc7d !important;
}
.color_bg_28634E {
  background-color: #28634e !important;
}
.color_bg_8E214B {
  background-color: #8e214b !important;
}
.color_bg_AB9849 {
  background-color: #ab9849 !important;
}

/* text color classes */

/* color classes */
.color_text_000000 {
  color: #000000 !important;
}

.color_text_FFFFFF {
  color: #ffffff !important;
}

.color_text_808080 {
  color: #808080 !important;
}

.color_text_FF6900 {
  color: #ff6900 !important;
}

.color_text_FCB900 {
  color: #fcb900 !important;
}

.color_text_7BDCB5 {
  color: #7bdcb5 !important;
}

.color_text_00D084 {
  color: #00d084 !important;
}

.color_text_8ED1FC {
  color: #8ed1fc !important;
}

.color_text_0693E3 {
  color: #0693e3 !important;
}

.color_text_ABB8C3 {
  color: #abb8c3 !important;
}

.color_text_EB144C {
  color: #eb144c !important;
}

.color_text_F78DA7 {
  color: #f78da7 !important;
}

.color_text_DB3E00 {
  color: #db3e00 !important;
}

.color_text_FCCB00 {
  color: #fccb00 !important;
}

.color_text_008B02 {
  color: #008b02 !important;
}

.color_text_006B76 {
  color: #006b76 !important;
}

.color_text_1273DE {
  color: #1273de !important;
}

.color_text_004DCF {
  color: #004dcf !important;
}

.color_text_5300EB {
  color: #5300eb !important;
}

.color_text_EB9694 {
  color: #eb9694 !important;
}
.color_text_CBBC7D {
  color: #cbcc7d !important;
}
.color_text_28634E {
  color: #28634e !important;
}
.color_text_8E214B {
  color: #8e214b !important;
}
.color_text_CBBC7D {
  color: #cbcc7d !important;
}
.color_text_AB9849 {
  color: #ab9849 !important;
}

.main_section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main_section_header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* center the image */
/* image size classes for object-fit */
.object-fit-fill {
  object-fit: fill;
}
.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}

/* background size for the background image */

.background-size-auto {
  background-size: auto !important;
}
.background-size-contain {
  background-size: contain !important;
}
.background-size-cover {
  background-size: cover !important;
}

/* background repeat for the background image */

.background-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

.background-repeat-repeat {
  background-repeat: repeat !important;
}

.align-img-center {
  display: block !important; /* if it is not block class will not work */
  margin-left: auto;
  margin-right: auto;
}

.img-width-fluid {
  width: 100%;
  height: auto;
}

.img-height-fluid {
  height: 100%;
  width: auto;
}

/* Margins Classes start */
.wp-mt-0 {
  margin-top: 0rem !important;
}
.wp-mt-1 {
  margin-top: 0.0625rem !important;
}
.wp-mt-2 {
  margin-top: 0.125rem !important;
}
.wp-mt-3 {
  margin-top: 0.1875rem !important;
}
.wp-mt-4 {
  margin-top: 0.25rem !important;
}
.wp-mt-5 {
  margin-top: 0.3125rem !important;
}
.wp-mt-6 {
  margin-top: 0.375rem !important;
}
.wp-mt-7 {
  margin-top: 0.4375rem !important;
}
.wp-mt-8 {
  margin-top: 0.5rem !important;
}
.wp-mt-9 {
  margin-top: 0.5625rem !important;
}
.wp-mt-10 {
  margin-top: 0.625rem !important;
}
.wp-mt-11 {
  margin-top: 0.6875rem !important;
}
.wp-mt-12 {
  margin-top: 0.75rem !important;
}
.wp-mt-13 {
  margin-top: 0.8125rem !important;
}
.wp-mt-14 {
  margin-top: 0.875rem !important;
}
.wp-mt-16 {
  margin-top: 1rem !important;
}
.wp-mt-18 {
  margin-top: 1.125rem !important;
}
.wp-mt-20 {
  margin-top: 1.25rem !important;
}
.wp-mt-24 {
  margin-top: 1.5rem !important;
}
.wp-mt-28 {
  margin-top: 1.75rem !important;
}
.wp-mt-32 {
  margin-top: 2rem !important;
}
.wp-mt-40 {
  margin-top: 2.5rem !important;
}
.wp-mt-45 {
  margin-top: 2.8125rem !important;
}
.wp-mt-50 {
  margin-top: 3.125rem !important;
}
.wp-mt-55 {
  margin-top: 3.4375rem !important;
}
.wp-mt-60 {
  margin-top: 3.75rem !important;
}
.wp-mt-65 {
  margin-top: 4.0625rem !important;
}
.wp-mt-70 {
  margin-top: 4.375rem !important;
}
.wp-mt-75 {
  margin-top: 4.6875rem !important;
}
.wp-mt-80 {
  margin-top: 5rem !important;
}
.wp-mb-0 {
  margin-bottom: 0rem !important;
}
.wp-mb-1 {
  margin-bottom: 0.0625rem !important;
}
.wp-mb-2 {
  margin-bottom: 0.125rem !important;
}
.wp-mb-3 {
  margin-bottom: 0.1875rem !important;
}
.wp-mb-4 {
  margin-bottom: 0.25rem !important;
}
.wp-mb-5 {
  margin-bottom: 0.3125rem !important;
}
.wp-mb-6 {
  margin-bottom: 0.375rem !important;
}
.wp-mb-7 {
  margin-bottom: 0.4375rem !important;
}
.wp-mb-8 {
  margin-bottom: 0.5rem !important;
}
.wp-mb-9 {
  margin-bottom: 0.5625rem !important;
}
.wp-mb-10 {
  margin-bottom: 0.625rem !important;
}
.wp-mb-11 {
  margin-bottom: 0.6875rem !important;
}
.wp-mb-12 {
  margin-bottom: 0.75rem !important;
}
.wp-mb-13 {
  margin-bottom: 0.8125rem !important;
}
.wp-mb-14 {
  margin-bottom: 0.875rem !important;
}
.wp-mb-16 {
  margin-bottom: 1rem !important;
}
.wp-mb-18 {
  margin-bottom: 1.125rem !important;
}
.wp-mb-20 {
  margin-bottom: 1.25rem !important;
}
.wp-mb-24 {
  margin-bottom: 1.5rem !important;
}
.wp-mb-28 {
  margin-bottom: 1.75rem !important;
}
.wp-mb-32 {
  margin-bottom: 2rem !important;
}
.wp-mb-40 {
  margin-bottom: 2.5rem !important;
}
.wp-mb-45 {
  margin-bottom: 2.8125rem !important;
}
.wp-mb-50 {
  margin-bottom: 3.125rem !important;
}
.wp-mb-55 {
  margin-bottom: 3.4375rem !important;
}
.wp-mb-60 {
  margin-bottom: 3.75rem !important;
}
.wp-mb-65 {
  margin-bottom: 4.0625rem !important;
}
.wp-mb-70 {
  margin-bottom: 4.375rem !important;
}
.wp-mb-75 {
  margin-bottom: 4.6875rem !important;
}
.wp-mb-80 {
  margin-bottom: 5rem !important;
}

.wp-ml-0 {
  margin-left: 0rem !important;
}
.wp-ml-1 {
  margin-left: 0.0625rem !important;
}
.wp-ml-2 {
  margin-left: 0.125rem !important;
}
.wp-ml-3 {
  margin-left: 0.1875rem !important;
}
.wp-ml-4 {
  margin-left: 0.25rem !important;
}
.wp-ml-5 {
  margin-left: 0.3125rem !important;
}
.wp-ml-6 {
  margin-left: 0.375rem !important;
}
.wp-ml-7 {
  margin-left: 0.4375rem !important;
}
.wp-ml-8 {
  margin-left: 0.5rem !important;
}
.wp-ml-9 {
  margin-left: 0.5625rem !important;
}
.wp-ml-10 {
  margin-left: 0.625rem !important;
}
.wp-ml-11 {
  margin-left: 0.6875rem !important;
}
.wp-ml-12 {
  margin-left: 0.75rem !important;
}
.wp-ml-13 {
  margin-left: 0.8125rem !important;
}
.wp-ml-14 {
  margin-left: 0.875rem !important;
}
.wp-ml-16 {
  margin-left: 1rem !important;
}
.wp-ml-18 {
  margin-left: 1.125rem !important;
}

.wp-ml-20 {
  margin-left: 1.25rem !important;
}
.wp-ml-24 {
  margin-left: 1.5rem !important;
}
.wp-ml-28 {
  margin-left: 1.75rem !important;
}
.wp-ml-32 {
  margin-left: 2rem !important;
}
.wp-ml-40 {
  margin-left: 2.5rem !important;
}
.wp-ml-45 {
  margin-left: 2.8125rem !important;
}
.wp-ml-50 {
  margin-left: 3.125rem !important;
}
.wp-ml-55 {
  margin-left: 3.4375rem !important;
}
.wp-ml-60 {
  margin-left: 3.75rem !important;
}
.wp-ml-65 {
  margin-left: 4.0625rem !important;
}
.wp-ml-70 {
  margin-left: 4.375rem !important;
}
.wp-ml-75 {
  margin-left: 4.6875rem !important;
}
.wp-ml-80 {
  margin-left: 5rem !important;
}
.wp-mr-0 {
  margin-right: 0rem !important;
}
.wp-mr-1 {
  margin-right: 0.0625rem !important;
}
.wp-mr-2 {
  margin-right: 0.125rem !important;
}
.wp-mr-3 {
  margin-right: 0.1875rem !important;
}
.wp-mr-4 {
  margin-right: 0.25rem !important;
}
.wp-mr-5 {
  margin-right: 0.3125rem !important;
}
.wp-mr-6 {
  margin-right: 0.375rem !important;
}
.wp-mr-7 {
  margin-right: 0.4375rem !important;
}
.wp-mr-8 {
  margin-right: 0.5rem !important;
}
.wp-mr-9 {
  margin-right: 0.5625rem !important;
}
.wp-mr-10 {
  margin-right: 0.625rem !important;
}
.wp-mr-11 {
  margin-right: 0.6875rem !important;
}
.wp-mr-12 {
  margin-right: 0.75rem !important;
}
.wp-mr-13 {
  margin-right: 0.8125rem !important;
}
.wp-mr-14 {
  margin-right: 0.875rem !important;
}
.wp-mr-16 {
  margin-right: 1rem !important;
}
.wp-mr-18 {
  margin-right: 1.125rem !important;
}
.wp-mr-20 {
  margin-right: 1.25rem !important;
}
.wp-mr-24 {
  margin-right: 1.5rem !important;
}
.wp-mr-28 {
  margin-right: 1.75rem !important;
}
.wp-mr-32 {
  margin-right: 2rem !important;
}
.wp-mr-40 {
  margin-right: 2.5rem !important;
}
.wp-mr-45 {
  margin-right: 2.8125rem !important;
}
.wp-mr-50 {
  margin-right: 3.125rem !important;
}
.wp-mr-55 {
  margin-right: 3.4375rem !important;
}
.wp-mr-60 {
  margin-right: 3.75rem !important;
}
.wp-mr-65 {
  margin-right: 4.0625rem !important;
}
.wp-mr-70 {
  margin-right: 4.375rem !important;
}
.wp-mr-75 {
  margin-right: 4.6875rem !important;
}
.wp-mr-80 {
  margin-right: 5rem !important;
}

/* Margins Classes End*/

/* Paddings Classes Start*/
.wp-pt-0 {
  padding-top: 0rem !important;
}
.wp-pt-1 {
  padding-top: 0.0625rem !important;
}
.wp-pt-2 {
  padding-top: 0.125rem !important;
}
.wp-pt-3 {
  padding-top: 0.1875rem !important;
}
.wp-pt-4 {
  padding-top: 0.25rem !important;
}
.wp-pt-5 {
  padding-top: 0.3125rem !important;
}
.wp-pt-6 {
  padding-top: 0.375rem !important;
}
.wp-pt-7 {
  padding-top: 0.4375rem !important;
}
.wp-pt-8 {
  padding-top: 0.5rem !important;
}
.wp-pt-9 {
  padding-top: 0.5625rem !important;
}
.wp-pt-10 {
  padding-top: 0.625rem !important;
}
.wp-pt-11 {
  padding-top: 0.6875rem !important;
}
.wp-pt-12 {
  padding-top: 0.75rem !important;
}
.wp-pt-13 {
  padding-top: 0.8125rem !important;
}
.wp-pt-14 {
  padding-top: 0.875rem !important;
}
.wp-pt-16 {
  padding-top: 1rem !important;
}
.wp-pt-18 {
  padding-top: 1.125rem !important;
}
.wp-pt-20 {
  padding-top: 1.25rem !important;
}
.wp-pt-24 {
  padding-top: 1.5rem !important;
}
.wp-pt-28 {
  padding-top: 1.75rem !important;
}
.wp-pt-32 {
  padding-top: 2rem !important;
}
.wp-pt-40 {
  padding-top: 2.5rem !important;
}
.wp-pt-45 {
  padding-top: 2.8125rem !important;
}
.wp-pt-50 {
  padding-top: 3.125rem !important;
}
.wp-pt-55 {
  padding-top: 3.4375rem !important;
}
.wp-pt-60 {
  padding-top: 3.75rem !important;
}
.wp-pt-65 {
  padding-top: 4.0625rem !important;
}
.wp-pt-70 {
  padding-top: 4.375rem !important;
}
.wp-pt-75 {
  padding-top: 4.6875rem !important;
}
.wp-pt-80 {
  padding-top: 5rem !important;
}
.wp-pb-0 {
  padding-bottom: 0rem !important;
}
.wp-pb-1 {
  padding-bottom: 0.0625rem !important;
}
.wp-pb-2 {
  padding-bottom: 0.125rem !important;
}
.wp-pb-3 {
  padding-bottom: 0.1875rem !important;
}
.wp-pb-4 {
  padding-bottom: 0.25rem !important;
}
.wp-pb-5 {
  padding-bottom: 0.3125rem !important;
}
.wp-pb-6 {
  padding-bottom: 0.375rem !important;
}
.wp-pb-7 {
  padding-bottom: 0.4375rem !important;
}
.wp-pb-8 {
  padding-bottom: 0.5rem !important;
}
.wp-pb-9 {
  padding-bottom: 0.5625rem !important;
}
.wp-pb-10 {
  padding-bottom: 0.625rem !important;
}
.wp-pb-11 {
  padding-bottom: 0.6875rem !important;
}
.wp-pb-12 {
  padding-bottom: 0.75rem !important;
}
.wp-pb-13 {
  padding-bottom: 0.8125rem !important;
}
.wp-pb-14 {
  padding-bottom: 0.875rem !important;
}
.wp-pb-16 {
  padding-bottom: 1rem !important;
}
.wp-pb-18 {
  padding-bottom: 1.125rem !important;
}
.wp-pb-20 {
  padding-bottom: 1.25rem !important;
}
.wp-pb-24 {
  padding-bottom: 1.5rem !important;
}
.wp-pb-28 {
  padding-bottom: 1.75rem !important;
}
.wp-pb-32 {
  padding-bottom: 2rem !important;
}
.wp-pb-40 {
  padding-bottom: 2.5rem !important;
}
.wp-pb-45 {
  padding-bottom: 2.8125rem !important;
}
.wp-pb-50 {
  padding-bottom: 3.125rem !important;
}
.wp-pb-55 {
  padding-bottom: 3.4375rem !important;
}
.wp-pb-60 {
  padding-bottom: 3.75rem !important;
}
.wp-pb-65 {
  padding-bottom: 4.0625rem !important;
}
.wp-pb-70 {
  padding-bottom: 4.375rem !important;
}
.wp-pb-75 {
  padding-bottom: 4.6875rem !important;
}
.wp-pb-80 {
  padding-bottom: 5rem !important;
}

.wp-pl-0 {
  padding-left: 0rem !important;
}
.wp-pl-1 {
  padding-left: 0.0625rem !important;
}
.wp-pl-2 {
  padding-left: 0.125rem !important;
}
.wp-pl-3 {
  padding-left: 0.1875rem !important;
}
.wp-pl-4 {
  padding-left: 0.25rem !important;
}
.wp-pl-5 {
  padding-left: 0.3125rem !important;
}
.wp-pl-6 {
  padding-left: 0.375rem !important;
}
.wp-pl-7 {
  padding-left: 0.4375rem !important;
}
.wp-pl-8 {
  padding-left: 0.5rem !important;
}
.wp-pl-9 {
  padding-left: 0.5625rem !important;
}
.wp-pl-10 {
  padding-left: 0.625rem !important;
}
.wp-pl-11 {
  padding-left: 0.6875rem !important;
}
.wp-pl-12 {
  padding-left: 0.75rem !important;
}
.wp-pl-13 {
  padding-left: 0.8125rem !important;
}
.wp-pl-14 {
  padding-left: 0.875rem !important;
}
.wp-pl-16 {
  padding-left: 1rem !important;
}
.wp-pl-18 {
  padding-left: 1.125rem !important;
}

.wp-pl-20 {
  padding-left: 1.25rem !important;
}
.wp-pl-24 {
  padding-left: 1.5rem !important;
}
.wp-pl-28 {
  padding-left: 1.75rem !important;
}
.wp-pl-32 {
  padding-left: 2rem !important;
}
.wp-pl-40 {
  padding-left: 2.5rem !important;
}
.wp-pl-45 {
  padding-left: 2.8125rem !important;
}
.wp-pl-50 {
  padding-left: 3.125rem !important;
}
.wp-pl-55 {
  padding-left: 3.4375rem !important;
}
.wp-pl-60 {
  padding-left: 3.75rem !important;
}
.wp-pl-65 {
  padding-left: 4.0625rem !important;
}
.wp-pl-70 {
  padding-left: 4.375rem !important;
}
.wp-pl-75 {
  padding-left: 4.6875rem !important;
}
.wp-pl-80 {
  padding-left: 5rem !important;
}
.wp-pr-0 {
  padding-right: 0rem !important;
}
.wp-pr-1 {
  padding-right: 0.0625rem !important;
}
.wp-pr-2 {
  padding-right: 0.125rem !important;
}
.wp-pr-3 {
  padding-right: 0.1875rem !important;
}
.wp-pr-4 {
  padding-right: 0.25rem !important;
}
.wp-pr-5 {
  padding-right: 0.3125rem !important;
}
.wp-pr-6 {
  padding-right: 0.375rem !important;
}
.wp-pr-7 {
  padding-right: 0.4375rem !important;
}
.wp-pr-8 {
  padding-right: 0.5rem !important;
}
.wp-pr-9 {
  padding-right: 0.5625rem !important;
}
.wp-pr-10 {
  padding-right: 0.625rem !important;
}
.wp-pr-11 {
  padding-right: 0.6875rem !important;
}
.wp-pr-12 {
  padding-right: 0.75rem !important;
}
.wp-pr-13 {
  padding-right: 0.8125rem !important;
}
.wp-pr-14 {
  padding-right: 0.875rem !important;
}
.wp-pr-16 {
  padding-right: 1rem !important;
}
.wp-pr-18 {
  padding-right: 1.125rem !important;
}
.wp-pr-20 {
  padding-right: 1.25rem !important;
}
.wp-pr-24 {
  padding-right: 1.5rem !important;
}
.wp-pr-28 {
  padding-right: 1.75rem !important;
}
.wp-pr-32 {
  padding-right: 2rem !important;
}
.wp-pr-40 {
  padding-right: 2.5rem !important;
}
.wp-pr-45 {
  padding-right: 2.8125rem !important;
}
.wp-pr-50 {
  padding-right: 3.125rem !important;
}
.wp-pr-55 {
  padding-right: 3.4375rem !important;
}
.wp-pr-60 {
  padding-right: 3.75rem !important;
}
.wp-pr-65 {
  padding-right: 4.0625rem !important;
}
.wp-pr-70 {
  padding-right: 4.375rem !important;
}
.wp-pr-75 {
  padding-right: 4.6875rem !important;
}
.wp-pr-80 {
  padding-right: 5rem !important;
}

/* Paddings Classes End*/

/* border-width classes for button */
/* .wp-border-0 {
  border-width: 0 !important;
}
.wp-border-1 {
  border-width: 0.0625rem !important;
}
.wp-border-2 {
  border-width: 0.125rem !important;
}
.wp-border-3 {
  border-width: 0.1875rem !important;
}
.wp-border-4 {
  border-width: 0.25rem !important;
}
.wp-border-5 {
  border-width: 0.3125rem !important;
}
.wp-border-6 {
  border-width: 0.375rem !important;
}
.wp-border-7 {
  border-width: 0.4375rem !important;
}
.wp-border-8 {
  border-width: 0.5rem !important;
}
.wp-border-9 {
  border-width: 0.5625rem !important;
}
.wp-border-10 {
  border-width: 0.625rem !important;
} */
/* border-width classes for button ended */

/* border-width classes for anchor */
.wp-border-0 {
  border-style: solid;
  border-width: 0 !important;
}
.wp-border-1 {
  border-style: solid;
  border-width: 0.0625rem !important;
}
.wp-border-2 {
  border-style: solid;
  border-width: 0.125rem !important;
}
.wp-border-3 {
  border-style: solid;
  border-width: 0.1875rem !important;
}
.wp-border-4 {
  border-style: solid;
  border-width: 0.25rem !important;
}
.wp-border-5 {
  border-style: solid;
  border-width: 0.3125rem !important;
}
.wp-border-6 {
  border-style: solid;
  border-width: 0.375rem !important;
}
.wp-border-7 {
  border-style: solid;
  border-width: 0.4375rem !important;
}
.wp-border-8 {
  border-style: solid;
  border-width: 0.5rem !important;
}
.wp-border-9 {
  border-style: solid;
  border-width: 0.5625rem !important;
}
.wp-border-10 {
  border-style: solid;
  border-width: 0.625rem !important;
}
/* border-width classes for anchor ended */

/* background-position classes for image */
.wp-bp-tl {
  background-position: top left !important;
  background-size: auto !important;
}
.wp-bp-tc {
  background-position: top center !important;
  background-size: auto !important;
}
.wp-bp-tr {
  background-position: top right !important;
  background-size: auto !important;
}
.wp-bp-cl {
  background-position: center left !important;
  background-size: auto !important;
}
.wp-bp-cc {
  background-position: center center !important;
  background-size: auto !important;
}
.wp-bp-cr {
  background-position: center right !important;
  background-size: auto !important;
}
.wp-bp-bl {
  background-position: bottom left !important;
  background-size: auto !important;
}
.wp-bp-bc {
  background-position: bottom center !important;
  background-size: auto !important;
}
.wp-bp-br {
  background-position: bottom right !important;
  background-size: auto !important;
}

/* background-position classes for image ended */

/* ============ Animation for Drawer ====== */

#LeftDrawer {
  box-shadow: 3px 3px 6px rgba(145, 145, 145, 0.619);
  transition: 0.3s ease-in-out;
  z-index: auto;
  -webkit-transition: ease-in-out 0.3s;
}

.section-item:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  box-shadow: 3px 3px 6px rgba(203, 199, 199, 0.619);
  transition: ease-in-out 0.3s;
}

/* Search Icon Left Drawer CSS */

.search-box {
  position: absolute;
  right: 46px;
  background: #f8f8f8;
  height: 30px;
  border-radius: 30px;
  display: flex;
  transition: 0.3s ease-in-out;
}
/* .search-box:hover > input{
  width: 160px;
  padding: 0.4rem 0.8rem;
} */
.input {
  display: none;
}
.search-box-width {
  width: 140px;
  display: block;
  padding: 0rem 0.8rem;
  border: none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  transition: 0.3s ease-in-out;
  line-height: 30px;
  color: #666666;
}

/* .search-box:hover > .icon{
  background: #f1f1f1;
} */
.icon:hover {
  background: #f1f1f1;
}

.icon-delete:hover i {
  color: #f23105 !important;
}
.icon i {
  color: #666666;
}
/* .searchInput {
  border:none;
  outline: none;
  background: none;
  font-size: 0.7rem;
  align-content: center;
  transition: 0.3s ease;
  line-height: 30px;
  color: #666666;
} */
.icon {
  float: right;
  width: 30px;
  font-size: 1rem;
  height: 30px;
  border-radius: 50%;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}
.element-card {
  width: 100px;
  font-size: 1rem;
  height: 100px;
  border-radius: 0.5rem;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}
.element-card i {
  color: #666666;
  font-size: 2rem;
}

/* Button variant classes */

.wb-btn-outlined {
  background: transparent !important;
  color: var(--wb-website-text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--wb-website-theme-secondary-color) !important;
  border-width: 0.125rem !important;
}

.wb-btn-outlined:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
  border: 0.125rem solid var(--wb-website-hover-background-color) !important;
}
.wb-btn-text {
  background: transparent !important;
  color: var(--wb-website-theme-secondary-color) !important;
}

.wb-btn-text:hover {
  color: var(--wb-website-text-primary-color) !important;
}
.wb-btn-contained {
  background: var(--wb-website-theme-secondary-color) !important;
  color: var(--wb-website-text-secondary-color) !important;
}

.wb-btn-contained:hover {
  background: var(--wb-website-hover-background-color) !important;
  color: var(--wb-website-hover-text-color) !important;
}


/* Width Classses */

.wb-width-10{
  width: 10%!important;
}
.wb-width-25{
  width: 25%!important;
}
.wb-width-50{
  width: 50%!important;
}
.wb-width-100{
  width: 100%!important;
}

/* letter Spacing */


.wb-ls-1{
  letter-spacing: 1px;
}
.wb-ls-2{
  letter-spacing: 2px;
}
.wb-ls-3{
  letter-spacing: 3px;
}
.wb-ls-5{
  letter-spacing: 5px;
}
.wb-ls-10{
  letter-spacing: 10px;
}
.wb-ls-20{
  letter-spacing: 20px;
}


/*  Calender CSS */

.calendar-body li {
  width: calc(100% / 7);
  font-size: 1.07rem;
  color: #414141;
}

.calendar-body .wb-calendar-dates li {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.wb-calendar-dates li.inactive {
  color: #aaa;
}

.wb-calendar-dates li.active {
  color: #fff;
}

.wb-calendar-dates li::before {
  position: absolute;
  content: "";
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.wb-calendar-dates li.active::before {
  background: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color);
}

.calendar-time-slots .slot span:hover{
  background-color: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color) !important;
}
.calendar-time-slots .slot .active{
  background-color: var(--wb-website-text-heading_color);
  color: var(--wb-website-hover-background-color)!important;
}

.calendar-container p {
  color: #414141;
}


